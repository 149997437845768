<template>
  <div>
    <b-card-header class="p-1" v-if="p_analyzeReports.length > 1">
      <b-row>
        <b-col sm="12" lg="6">
          <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Raporlar
        </b-col>
        <b-col sm="12" lg="6" style="text-align: right;">
          <b-button style="margin-right: 5px; padding: 0px;" variant="secondary" title="sola ilerle" size="md" @click="f_moveChartData('', 'left')">
            <img src="@/icon/1277677.png" style="width: 2em;" />
          </b-button>
          <b-button style="margin-right: 5px; padding: 0px;" variant="secondary" title="sağa ilerle" size="md" @click="f_moveChartData('', 'right')">
            <img src="@/icon/1277676.png" style="width: 2em;" />
          </b-button>
          <b-button style="margin-right: 5px; padding: 0px;" variant="secondary" title="tümünü göster" size="md" @click="f_moveChartData('', 'all')">
            <img src="@/icon/453632.png" style="width: 2em;" />
          </b-button>
          <b-button style="margin-right: 5px; padding: 0px;" variant="secondary" title="reset" size="md" @click="f_moveChartData('', 'reset')">
            <img src="@/icon/937437.png" style="width: 2em;" />
          </b-button>
        </b-col>
      </b-row>
    </b-card-header>
    <b-row style="margin: 0px;">
      <b-col cols="12">
        <template v-for="(report, report_index) in p_analyzeReports">
          <template v-if="report && report.data && report.data.general">
            <template v-if="report.data.general.report_type && report.data.general.report_type.val">
              <template v-if="report.data.general.report_type.val.value === 'total_count'">
                <template v-if="report.data.general.report_name_selectable && report.data.general.report_name_selectable.val && report.data.general.report_name_selectable.val.value">
                  <b-row style="margin: 0px;">
                    <b-col sm="12" lg="12" style="padding: 3px;">
                      <b-card no-body bg-variant="whitey" style="margin-bottom: 3px;">
                        <b-row style="margin: 0px;">
                          <b-col cols="8" style="padding: 10px;">
                            <strong style="color: #7e0303;"> {{ report.label.toLocaleUpperCase() }} </strong>
                            <small v-if="report.date">({{ f_dateFormat(report.date) }})</small>
                          </b-col>
                          <b-col cols="4" style="padding: 10px; font-size: 16px;">
                            <strong>{{ report.data.general.total_count.val }}</strong>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                  </b-row>
                </template>
                <template v-else>
                  <b-row style="margin: 0px;">
                    <b-col sm="12" lg="12" style="padding: 3px;">
                      <b-card no-body bg-variant="whitey">
                        <b-row style="margin: 0px;">
                          <b-col cols="8" style="padding: 10px;">
                            <strong style="color: #7e0303;">
                                <template v-if="report.data.general.report_name_manuel && report.data.general.report_name_manuel.val">
                                  {{ report.data.general.report_name_manuel.val.toLocaleUpperCase() }}
                                </template>
                              </strong>
                            <small v-if="report.date">({{ f_dateFormat(report.date) }})</small>
                          </b-col>
                          <b-col cols="4" style="padding: 10px; font-size: 16px;">
                            <strong>{{ report.data.general.total_count.val }}</strong>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                  </b-row>
                </template>
              </template>
            </template>
          </template>
        </template>
        <template v-for="(report, report_index) in p_analyzeReports">
          <template v-if="report && report.data && report.data.general">
            <template v-if="report.data.general.report_type && report.data.general.report_type.val">
              <template v-if="report.data.general.report_type.val.value === 'percent'">
                <b-row style="margin: 0px;">
                  <b-col sm="12" lg="12" style="padding: 3px;">
                    <b-card no-body bg-variant="whitey">
                      <b-row style="margin: 0px;">
                        <b-col cols="8" style="padding: 10px;">
                          <strong style="color: #7e0303;">
                              <template v-if="report.data.general.report_name_selectable && report.data.general.report_name_selectable.val && report.data.general.report_name_selectable.val.value">
                                {{ report.label.toLocaleUpperCase() }}
                              </template>
                              <template v-if="report.data.general.report_name_manuel && report.data.general.report_name_manuel.val">
                                {{ report.data.general.report_name_manuel.val.toLocaleUpperCase() }}
                              </template>
                            </strong>
                          <small v-if="report.date">({{ f_dateFormat(report.date) }})</small>
                        </b-col>
                        <b-col cols="4" style="padding: 10px; font-size: 16px;">
                          <strong>{{ report.data.general.percent.val }}</strong>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
              </template>
            </template>
          </template>
        </template>
        <template v-for="(report, report_index) in p_analyzeReports">
          <template v-if="report && report.data && report.data.general">
            <template v-if="report.data.general.report_type && report.data.general.report_type.val">
              <template v-if="report.data.general.report_type.val.value === 'chart'">
                <b-row :key="'chart_' + report_index" v-if="report.data.general.chart_type && report.data.general.chart_type.val && report.data.general.chart_type.val.value" style="margin: 0px; border-bottom: solid 1px skyblue;">
                  <b-col sm="12" lg="11">
                    <b-row style="margin: 0px;">
                      <b-col cols="12">
                        <img src="@/icon/2500447.png" style="width: 2em;" @click="d_chartInfoView.report_index = report_index; d_chartInfoView.show = true;" />
                        <small style="color: #7e0303;" v-if="report.data.general.report_name_selectable && report.data.general.report_name_selectable.val && report.data.general.report_name_selectable.val.value"> {{ report.label.toLocaleUpperCase() }} </small>
                        <small style="color: #7e0303;" v-if="report.data.general.report_name_manuel && report.data.general.report_name_manuel.val"> {{ report.data.general.report_name_manuel.val.toLocaleUpperCase() }} </small>
                        <small v-if="report.date">({{ f_dateFormat(report.date) }})</small>
                      </b-col>
                      <!-- <b-col cols="6" style="text-align: right;"> -->
                      <!-- 
                          <b-button style="margin-right: 5px; padding: 0px;" variant="secondary" title="sola ilerle" size="md" @click="f_moveChartData(report_index, 'left')">
                            <img src="@/icon/1277677.png" style="width: 2em;" />
                          </b-button>
                          <b-button style="margin-right: 5px; padding: 0px;" variant="secondary" title="sağa ilerle" size="md" @click="f_moveChartData(report_index, 'right')">
                            <img src="@/icon/1277676.png" style="width: 2em;" />
                          </b-button>
                          <b-button style="margin-right: 5px; padding: 0px;" variant="secondary" title="tümünü göster" size="md" @click="f_moveChartData(report_index, 'all')">
                            <img src="@/icon/453632.png" style="width: 2em;" />
                          </b-button>
                          <b-button style="margin-right: 5px; padding: 0px;" variant="secondary" title="tümünü göster" size="md" @click="f_moveChartData(report_index, 'reset')">
                            <img src="@/icon/937437.png" style="width: 2em;" />
                          </b-button>
                           -->
                      <!-- </b-col> -->
                    </b-row>
                    <template v-if="report.data.general.chart_type.val.value === 'bar'">
                      <div :style="f_calculateChartStyle(report)">
                        <bar-view v-if="report.show" :height="300" :width="f_calculateChartWidth(report)" :chartId="'chart_' + report_index.toString() + '_' + f_createChartId()" :chart_data="f_getChartData(report)" />
                      </div>
                    </template>
                    <template v-if="report.data.general.chart_type.val.value === 'doughnut'">
                      <div :style="f_calculateChartStyle(report)">
                        <doughnut v-if="report.show" -view :height="300" :width="f_calculateChartWidth(report)" :chartId="'chart_' + report_index.toString() + '_' + f_createChartId()" :chart_data="f_getChartData(report)" />
                      </div>
                    </template>
                    <template v-if="report.data.general.chart_type.val.value === 'line'">
                      <div :style="f_calculateChartStyle(report)">
                        <line-view v-if="report.show" :height="300" :width="f_calculateChartWidth(report)" :chartId="'chart_' + report_index.toString() + '_' + f_createChartId()" :chart_data="f_getChartData(report)" />
                      </div>
                    </template>
                    <template v-if="report.data.general.chart_type.val.value === 'pie'">
                      <div :style="f_calculateChartStyle(report)">
                        <bar-view v-if="report.show" :height="300" :width="f_calculateChartWidth(report)" :chartId="'chart_' + report_index.toString() + '_' + f_createChartId()" :chart_data="f_getChartData(report)" />
                      </div>
                    </template>
                  </b-col>
                </b-row>
              </template>
            </template>
          </template>
        </template>
      </b-col>
    </b-row>
    <b-modal v-if="d_chartInfoView.show" id="d_chartInfoView" v-model="d_chartInfoView.show" scrollable hide-footer title="Grafik Bilgileri" centered header-bg-variant="info" header-text-variant="dark">
      <p class="my-4" style="word-wrap: break-word;" v-if="p_analyzeReports[d_chartInfoView.report_index] && p_analyzeReports[d_chartInfoView.report_index].data && p_analyzeReports[d_chartInfoView.report_index].data.general && p_analyzeReports[d_chartInfoView.report_index].data.general.info && p_analyzeReports[d_chartInfoView.report_index].data.general.info.val">
        {{ p_analyzeReports[d_chartInfoView.report_index].data.general.info.val }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import PieView from '@/components/chart/PieView';
import LineView from '@/components/chart/LineView';
import ScatterView from '@/components/chart/ScatterView';
import BarView from '@/components/chart/BarView';
import DoughnutView from '@/components/chart/DoughnutView';
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'AnalyzeReportView',
  computed: mapGetters({
    lang: 'lang',
    device: 'device'
  }),
  props: {
    p_analyzeReports: {
      type: Array,
      required: true
    }
  },
  components: {
    LineView,
    ScatterView,
    BarView,
    DoughnutView,
    PieView
  },
  data () {
    return {
      d_chartInfoView: { 'show': false, 'report_index': '' },
      d_timerControlProjectUpdate: { 'timer': 0 },
      d_totalCountPriorityReportOrder: ['totalcount_total_marketing_revenue', 'totalcount_total_primary_marketing_revenue', 'totalcount_total_secondary_marketing_revenue', 'totalcount_protocolopen', 'totalcount_primary_protocolopen', 'totalcount_secondary_protocolopen', 'totalcount_appointment', 'totalcount_primary_appointment', 'totalcount_secondary_appointment'],
      d_reportType: {
        'selected': '',
        'list': [{
          "value": "chart",
          "label": "grafik"
        }, {
          "value": "total_count",
          "label": "toplam adet"
        }, {
          "value": "percent",
          "label": "yüzde"
        }, {
          "value": "",
          "label": "hepsi"
        }]
      },
      d_reportNameType: {
        'selected': '',
        'list': [{
          "value": "selectable",
          "label": "seçilebilir"
        }, {
          "value": "manuel",
          "label": "manuel"
        }, {
          "value": "",
          "label": "hepsi"
        }]
      },
    };
  },
  created: function () {
    for (let report_index in this.p_analyzeReports) {
      this.p_analyzeReports[report_index].start = 0;
      this.p_analyzeReports[report_index].show = true;
      this.p_analyzeReports[report_index].end = 1000000;
      this.p_analyzeReports[report_index].max_show_point = 50;
      /*
      try {
        this.p_analyzeReports[report_index].start = this.p_analyzeReports[report_index].data.general.chart_data_label.list.length - this.p_analyzeReports[report_index].max_show_point;
        this.p_analyzeReports[report_index].end = this.p_analyzeReports[report_index].data.general.chart_data_label.list.length;
      } catch (err) {}
      */
    }
  },
  mounted: function () {},
  destroyed () {},
  methods: {
    f_dateFormat: function (date) {
      if (date) {
        if (date.indexOf('T') !== -1) {
          return moment(date, "YYYY-MM-DDTHH:mm:ss").format("DD.MM.YYYY HH:mm");
        } else {
          return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm");
        }
      } else {
        return "";
      }
    },
    f_createChartId: function () {
      let x = Math.floor((Math.random() * 100) + 1);
      return x;
    },
    f_openCloseChart: function (report_index) {
      this.p_analyzeReports[report_index].show = false;
      this.$forceUpdate();
      setTimeout(function () {
        this.p_analyzeReports[report_index].show = true;
        this.$forceUpdate();
      }.bind(this), 5);
    },
    f_moveChartData: function (report_index = '', direction = '') {
      if (report_index !== '') {
        if (direction === 'right') {
          if (this.p_analyzeReports[report_index].end < this.p_analyzeReports[report_index].data.general.chart_data_label.list.length) {
            this.p_analyzeReports[report_index].start += 1;
            this.p_analyzeReports[report_index].end += 1;
            this.f_openCloseChart(report_index);
          }
        } else if (direction === 'left') {
          if (this.p_analyzeReports[report_index].start !== 0) {
            this.p_analyzeReports[report_index].start -= 1;
            this.p_analyzeReports[report_index].end -= 1;
            this.f_openCloseChart(report_index);
          }
        } else if (direction === 'all') {
          this.p_analyzeReports[report_index].start = 0;
          this.p_analyzeReports[report_index].end = 10000000;
          this.f_openCloseChart(report_index);
        } else if (direction === 'reset') {
          this.p_analyzeReports[report_index].start = this.p_analyzeReports[report_index].data.general.chart_data_label.list.length - this.p_analyzeReports[report_index].max_show_point;
          this.p_analyzeReports[report_index].end = this.p_analyzeReports[report_index].data.general.chart_data_label.list.length;
          this.f_openCloseChart(report_index);
        }

      } else {
        for (let i in this.p_analyzeReports) {
          if (direction === 'right') {
            if (this.p_analyzeReports[i].end < this.p_analyzeReports[i].data.general.chart_data_label.list.length) {
              this.p_analyzeReports[i].start += 1;
              this.p_analyzeReports[i].end += 1;
              this.f_openCloseChart(i);
            }
          } else if (direction === 'left') {
            if (this.p_analyzeReports[i].start !== 0) {
              this.p_analyzeReports[i].start -= 1;
              this.p_analyzeReports[i].end -= 1;
              this.f_openCloseChart(i);
            }
          } else if (direction === 'all') {
            this.p_analyzeReports[i].start = 0;
            this.p_analyzeReports[i].end = 10000000;
            this.f_openCloseChart(i);
          } else if (direction === 'reset') {
            this.p_analyzeReports[i].start = this.p_analyzeReports[i].data.general.chart_data_label.list.length - this.p_analyzeReports[i].max_show_point;
            this.p_analyzeReports[i].end = this.p_analyzeReports[i].data.general.chart_data_label.list.length;
            this.f_openCloseChart(i);
          }
        }
      }
    },
    f_calculateChartWidth: function (report) {
      let width_val = window.innerWidth - 375;
      try {
        width_val = parseInt(report.data.general.chart_width.val);
      } catch (err) {}
      return width_val;
    },
    f_calculateChartStyle: function (report) {
      let width_val = window.innerWidth - 375;
      // console.log(width_val);
      try {
        width_val = parseInt(report.data.general.chart_width.val);
      } catch (err) {}
      let width_px = width_val.toString() + 'px';
      let style = 'margin: 10px; overflow-x: auto;';
      if (report.data.general.chart_type.val.value === 'bar') {
        style += 'height: 300px; width: ' + width_px + ';';
      } else if (report.data.general.chart_type.val.value === 'line') {
        style += 'height: 300px; width: ' + width_px + ';';
      } else if (report.data.general.chart_type.val.value === 'doughnut') {
        style += 'height: 300px; width: 300px;';
      } else if (report.data.general.chart_type.val.value === 'pie') {
        style += 'height: 300px; width: 300px;';
      }
      return style;
    },
    f_getChartData: function (report) {
      let chart_data_label_list = [];
      let dataset_list = [];
      try {
        for (let i in report.data.general.chart_data_label.list) {
          if (parseInt(i) >= report.start && parseInt(i) <= report.end) {
            let chart_data_label_info_lbl = report.data.general.chart_data_label.list[i].chart_data_label_info.val;
            if (chart_data_label_info_lbl.length > 30) {
              chart_data_label_info_lbl = chart_data_label_info_lbl.substring(0, 30) + '..';
            }
            chart_data_label_list.push(chart_data_label_info_lbl);
          }
        }
      } catch (err) {}
      try {
        for (let i in report.data.general.chart_data_dataset.list) {
          let new_dataset = {
            'data': [],
            'backgroundColor': [],
            'label': report.data.general.chart_data_dataset.list[i].chart_data_dataset_label.val
          };
          if (new_dataset.label.length > 30) {
            new_dataset.label = new_dataset.label.substring(0, 30) + '..';
          }
          if (report.data.general.chart_type.val.value === 'line') {
            new_dataset.lineTension = 0;
            new_dataset.showLine = true;
          }
          try {
            for (let k in report.data.general.chart_data_dataset.list[i].chart_data_dataset_data.list) {
              if (parseInt(k) >= report.start && parseInt(k) <= report.end) {
                let data_value = parseFloat(report.data.general.chart_data_dataset.list[i].chart_data_dataset_data.list[k].chart_data_dataset_data_value.val);
                new_dataset.data.push(data_value);
                let backgroundColor = report.data.general.chart_data_dataset.list[i].chart_data_dataset_data.list[k].chart_data_dataset_data_value_background_color.val;
                new_dataset.backgroundColor.push(backgroundColor);
              }
            }
          } catch (err) {}
          // dataset hazırlandıktan sonra liste içerisine ekleniyor.
          dataset_list.push(new_dataset);
        }
      } catch (err) {}
      /*
        {
          "chart_data": {
            "labels": ['SMS % ', 'Mail % ', 'Push % ', 'Telefon ile arama % ', 'Web Push % '],
            "datasets": [{
              "data": [20, 30, 30, 10, 10],
              "backgroundColor": ['rgba(0,28,73,10)', 'rgba(69,75,10,10)', 'rgba(49,73,91,10)', 'rgba(75,91,84,10)', 'rgba(70,52,75,10)'],
              "label": "Kanal operasyonu yüzdeleri"
            }]
          }
        }
      */
      let chart_data = {
        backgroundColor: "#fff",
        labels: chart_data_label_list,
        datasets: dataset_list
      };
      /*
        datasets: [{
          label: caption,
          lineTension: 0,
          borderColor: '#20a8d8',
          backgroundColor: '#ffffff00',
          showLine: true,
          // backgroundColor: hexToRgba('#20a8d8', 90),
          data: []
        }]
      */
      return chart_data;
    },
  },
  watch: {}
};

</script>

<style type="text/css">
canvas {
  background-color: white;
}

</style>

