<template>
  <div>
    <b-card no-body :class="[d_fullScreen ?'full-screen-mode' :'normal-mode']">
      <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
        <b-row style="margin: 0px; padding: 5px;">
          <b-col cols="2">
            <img src="@/icon/665778.png" class="img-rounded img-responsive" style="width: 2em;" /> Proje Kopyalama
          </b-col>
          <b-col cols="10" style="text-align: right;">
            <b-button size="md" variant="primary" @click="f_saveProjectCopy()" style="margin-right: 5px;">
              <img src="@/icon/2087726.png" style="width: 2em;" /> Projeyi Kopyala
            </b-button>
            <b-button size="md" :variant="d_fullScreen ?'warning' :'secondary'" :style="d_fullScreen ?'margin-right: 5px; border: solid 2px green;' :'margin-right: 5px;'" @click="d_fullScreen ? d_fullScreen = false : d_fullScreen = true">
              <img src="@/icon/629153.png" :title="$t('wdm16.6589')" style="width: 2em;" />
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-row style="margin: 0px; padding: 5px;">
        <b-col cols="6">
          <img src="@/icon/665778.png" class="img-rounded img-responsive" style="width: 2em;" /> <strong>Proje Adı :</strong>
        </b-col>
        <b-col cols="6">
          <b-textarea v-model="d_projectCopyName" style="width: 100%;"></b-textarea>
        </b-col>
      </b-row>
      <b-row style="margin: 0px; padding: 5px;">
        <b-col cols="6">
          <img src="@/icon/665778.png" class="img-rounded img-responsive" style="width: 2em;" /> <strong>Kolon Seçim Tipi:</strong>
        </b-col>
        <b-col cols="6" style="text-align: right;">
          <b-form-select v-model="d_columnSelectionType" style="padding: 3px; background-color: transparent;">
            <option value="all">tüm kolonlar</option>
            <option value="selected">seçili kolonlar</option>
            <option value="without_columns">kolonlar hariç</option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row style="margin: 0px; padding: 5px;">
        <b-col cols="6">
          <img src="@/icon/665778.png" class="img-rounded img-responsive" style="width: 2em;" /> <strong>Dataset Kopyalama Tipi:</strong>
        </b-col>
        <b-col cols="6" style="text-align: right;">
          <b-form-select v-model="d_datasetCopyType" style="padding: 3px; background-color: transparent;">
            <option value="copy_dataset">datasetleri kopyala</option>
            <option value="dont_copy_dataset">datasetleri kopyalama</option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row v-if="d_datasetCopyType === 'copy_dataset'" style="margin: 0px; padding: 5px;">
        <b-col cols="6">
          <img src="@/icon/665778.png" class="img-rounded img-responsive" style="width: 2em;" /> <strong>Dataset Kopyalama Metodu:</strong>
        </b-col>
        <b-col cols="6" style="text-align: right;">
          <b-form-select v-model="d_datasetCopyMethod" style="padding: 3px; background-color: transparent;">
            <option value="first_x_rows">ilk X satır</option>
            <option value="by_algorithm">algoritma ile</option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row v-if="d_datasetCopyMethod === 'first_x_rows'" style="margin: 0px; padding: 5px;">
        <b-col cols="6">
          <img src="@/icon/665778.png" class="img-rounded img-responsive" style="width: 2em;" /> <strong>Dataset Kopyalama Metodu:</strong>
        </b-col>
        <b-col cols="6" style="text-align: right;">
          <b-form-input type="number" v-model="d_copyFirstXRows" style="padding: 3px; background-color: transparent;"></b-form-input>
        </b-col>
      </b-row>
      <template v-if="d_datasetCopyMethod === 'by_algorithm'">
        <b-row key="d_datasetCopyMethod_by_algorithm" style="margin: 0px; padding: 5px;">
          <b-col sm="12">
            <b-card no-body header-tag="header" style="margin-bottom: 0px;">
              <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                <strong>Kolon kural algoritmaları</strong>
              </b-card-header>
              <wdm-rule-a :data_type="d_columnRule.data_type" :option_data="d_columnRule.option_data" :prepare_data="d_columnRule.prepare_data" :query_list="d_columnRule.query_list" :compile_str_array="d_columnRule.compile_str_array" :compile_str_date="d_columnRule.compile_str_date" :wdmr_change="d_columnRule.wdmr_change" :p_wdmList="d_columnRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_columnRule.wps_data" :p_wdmRuleAsettings="d_columnRule.wra_settings" :polestar="true" :compile_str_text="d_columnRule.compile_str_text"></wdm-rule-a>
            </b-card>
          </b-col>
        </b-row>
      </template>
      <template v-if="d_columnSelectionType === 'selected'">
        <pole-star-column-feature :column_list="column_list" :p_selectedProjectData="d_projectCopy" :d_tableModeSettings="d_tableModeSettings" :p_columnSelectionMode="true" :mother="'polestar_project_copy'" :p_polestarSettings="p_polestarSettings" :p_userPolestarPermissions="p_userPolestarPermissions"></pole-star-column-feature>
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  default as PoleStarColumnFeature
} from '@/components/widgets/PoleStarColumnFeature';

import vSelect from 'vue-select';
import PoleStarService from '@/services/polestar';
import { ClsRule } from '@/services/public/rule';
import { ClsPolestar } from "@/services/public/polestar";
import { mapGetters } from 'vuex';
// import moment from 'moment';
import {
  default as WdmRuleA
} from '@/components/widgets/WdmRuleA';
import {
  default as PolestarResult
} from "@/components/widgets/PolestarResult";
import {
  default as Modal
} from '@/components/widgets/Modal';

export default {
  name: 'PolestarProjectCopy',
  props: {
    p_selectedProjectData: {
      type: Object,
      required: true
    },
    p_polestarSettings: {
      type: Object,
      required: true
    },
    p_userPolestarPermissions: {
      type: Array,
      required: true
    }
  },
  components: {
    Modal,
    WdmRuleA,
    PolestarResult,
    vSelect,
    PoleStarColumnFeature
  },
  computed: {
    ...mapGetters({
      device: 'device'
    })
  },
  created: function () {
    // this.user = JSON.parse(localStorage.getItem('user'));
    this.d_projectCopy = JSON.parse(JSON.stringify(this.p_selectedProjectData));
    this.d_projectWdmList = ClsPolestar.f_createProjectWdmList(this.d_projectCopy);
    this.column_list = this.d_projectCopy.design.column_list;
    this.d_projectCopyName = this.d_projectCopy.label;
    ClsPolestar.f_prepareColumnRuleOptionData(this.d_columnRule, this.column_list);
  },
  mounted: function () {},
  data () {
    return {
      d_tableModeSettings: { 'start': 0, 'end': 20, 'current': 1, 'perpage': 20, 'show_selected_columns': [] },
      d_fullScreen: false,
      d_projectCopy: {},
      d_projectWdmList: [],
      column_list: [],
      d_copyFirstXRows: 10,
      d_datasetCopyMethod: '',
      d_datasetCopyType: 'dont_copy_dataset',
      d_columnSelectionType: 'all',
      d_projectCopyName: '',
      d_columnRule: {
        'date_ability': '',
        'wdmr_change': { 'rule': 0 },
        'data_type': '',
        'option_data': {},
        'prepare_data': {},
        'query_list': [],
        'compile_str_date': [],
        'compile_str_text': [],
        'compile_str_array': [],
        'wra_settings': {
          'mode': 'param',
          'options': [
            { 'label': 'WDM & QUERY', 'value': 'wdm_query' },
            { 'label': 'PARAM', 'value': 'param' },
            { 'label': 'PARAM & QUERY', 'value': 'param_query' }
          ]
        },
        'wps_data': [{
          'operation': '',
          'selected_value': '',
          'loc': [],
          'val_list': [],
          'selected_wdm': '',
          'd_level': 0,
          'd_level_count': 0,
          'significant': 'true'
        }],
        'wdm_list': []
      },
    }
  },
  methods: {
    f_saveProjectCopy: function () {
      if (confirm('Projeyi kopyalama işlemine emin misiniz ?')) {
        if (this.d_datasetCopyMethod === 'by_algorithm') {
          let parenthesis_control = ClsRule.f_controlParanthesisDesign(this.d_columnRule.compile_str_array);
          if (parenthesis_control) {
            // When we write our code with mode param, we have to convert it to query_list format to make a filter.
            if (this.d_columnRule.wra_settings.mode === 'param') {
              ClsRule.f_paramToQuery(this.d_columnRule.query_list, this.d_columnRule.compile_str_array, this.d_columnRule.wps_data);
            }
            let column_rule_filter = {
              'query_list': this.d_columnRule.query_list,
              'compile_str_array': this.d_columnRule.compile_str_array,
              'compile_str_text': this.d_columnRule.compile_str_text,
              'compile_str_date': this.d_columnRule.compile_str_date
            };
            if (this.d_columnSelectionType === 'selected' && this.d_tableModeSettings.show_selected_columns.length === 0) {
              alert('Lütfen kolon seçimi yapınız');
              return;
            }
            let data = {
              'column_rule_filter': column_rule_filter,
              'first_x_rows': this.d_copyFirstXRows,
              'dataset_copy_method': this.d_datasetCopyMethod,
              'dataset_copy_type': this.d_datasetCopyType,
              'wdmr3_data': this.d_projectCopy,
              'column_index_list': this.d_tableModeSettings.show_selected_columns,
              'column_selection_type': this.d_columnSelectionType,
              'name': this.d_projectCopyName
            };
            data.permission_control = { 'value': 'wanalyzer_create-project', 'i_used_this_permission': 1 };
            this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Proje kopyalama işlemi başlatıldı. Lütfen bekleyiniz.' } });
            PoleStarService.save_project_copy(data)
              .then(resp => {
                this.$store.commit('Loading', { 'status': false, 'data': {} });
                if (resp.data.status === 'success') {
                  alert('Başarıyla tamamlandı.');
                  let route = this.$router.resolve({ path: '/polestar/wisdomstats', query: { 'project_id': resp.data.result.project_id } });
                  window.open(route.href, '_blank');
                } else {
                  let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
                  this.$store.commit('modal', modal_data);
                  this.$store.commit('modal_show', true);
                }
              });
          } else {
            let msg = 'Lütfen parentezlerinizi kontrol ediniz.';
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Algoritma kuralı oluşturma işlemi hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
        } else {
          if (this.d_columnSelectionType === 'selected' && this.d_tableModeSettings.show_selected_columns.length === 0) {
            alert('Lütfen kolon seçimi yapınız');
          } else {
            let data = {
              'column_rule_filter': [],
              'first_x_rows': this.d_copyFirstXRows,
              'dataset_copy_method': this.d_datasetCopyMethod,
              'dataset_copy_type': this.d_datasetCopyType,
              'wdmr3_data': this.d_projectCopy,
              'column_index_list': this.d_tableModeSettings.show_selected_columns,
              'column_selection_type': this.d_columnSelectionType,
              'name': this.d_projectCopyName
            };
            data.permission_control = { 'value': 'wanalyzer_create-project', 'i_used_this_permission': 1 };
            this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Proje kopyalama işlemi başlatıldı. Lütfen bekleyiniz.' } });
            PoleStarService.save_project_copy(data)
              .then(resp => {
                this.$store.commit('Loading', { 'status': false, 'data': {} });
                if (resp.data.status === 'success') {
                  alert('Başarıyla tamamlandı.');
                  let route = this.$router.resolve({ path: '/polestar/wisdomstats', query: { 'project_id': resp.data.result.project_id } });
                  window.open(route.href, '_blank');
                } else {
                  let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
                  this.$store.commit('modal', modal_data);
                  this.$store.commit('modal_show', true);
                }
              });
          }
        }
      }
    }
  },
  watch: {}
};

</script>

<style type="text/css">
.normal-mode {}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

