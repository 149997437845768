// import UserService from "../users";
// import store from '../../store';
var level = 0;
var popup_element_list = [];
class ClsMenu {
  static f_turnOverButtonList = function(vm, button_list, popup_element, parent_element, targetPageElement, specific_arguments, d_icons) {
    level = 0;
    popup_element_list = [];
    this.f_deeperMenu(vm, button_list, popup_element, parent_element, targetPageElement, specific_arguments, d_icons);
  };
  static f_deeperMenu = function(vm, button_list, popup_element, parent_element, targetPageElement, specific_arguments, d_icons) {
    // specific_arguments = {'patient_id': '', ...}
    // You must define your specific_arguments for required parameters and arguments if you define it as type 'var'
    // console.log(specific_arguments);
    popup_element_list.push(popup_element.id);
    for (let b in button_list.param_order) {
      let param = button_list.param_order[b];
      let b_data = button_list.parameters[param];
      if (b_data.require && b_data.require.length > 0) {
        let is_continue = true;
        for (let req_ind in b_data.require) {
          let require_data = b_data.require[req_ind];
          if (specific_arguments[require_data['name']] !== undefined) {
            if (require_data['res'] === 'true') {
              if (!specific_arguments[require_data['name']]) {
                is_continue = false;
                break;
              }
            } else {
              if (specific_arguments[require_data['name']]) {
                is_continue = false;
                break;
              }
            }
          } else {
            console.log('Please define your required variables in specific_arguments');
            return;
          }
        }
        if (!is_continue) {
          continue;
        }
      }
      for (let p in button_list.parent_order) {
        let el = button_list.parent_order[p];
        b_data['elements'][el]['doc'] = { 'dom_el': document.createElement(el) };
        b_data['elements'][el]['doc'].id = b + '_' + param + '_' + el;
        b_data['elements'][el]['doc'].id = b + '_' + param + '_' + el;
        specific_arguments.dom_element_id = b_data['elements'][el]['doc'].id;
        for (let attr_ind in b_data['elements'][el].attr) {
          if (attr_ind === 'functions') {
            let func_list = b_data['elements'][el].attr['functions'];
            for (let f_ind in func_list) {
              let func_data = func_list[f_ind];
              // func_str must be like =>  '(function a() {})' => then we can define a function for eval function
              let func_str = '(function() {' + 'vm.' + func_data.name + '(';
              for (let f_arg_ind in func_data.arguments) {
                let f_arg = func_data.arguments[f_arg_ind];
                if (f_arg['type'] === 'var') {
                  if (specific_arguments[f_arg['name']] !== undefined) {
                    if (typeof specific_arguments[f_arg['name']] === 'string') {
                      func_str += '\'' + specific_arguments[f_arg['name']] + '\'';
                    } else {
                      func_str += specific_arguments[f_arg['name']];
                    }
                  } else {
                    console.log('Please define your argument in specific_arguments ', f_arg['name']);
                    return;
                  }
                } else {
                  func_str += '\'' + f_arg['name'] + '\'';
                }
                if (parseInt(f_arg_ind) !== func_data.arguments.length - 1) {
                  func_str += ', ';
                } else {
                  func_str += ')';
                }
              }
              func_str += '})';
              // when we come here, we have a function string looks like => this.f_openHospitalPatientRelation(dom_element_id, row_ind)
              // console.log(func_str);
              // console.log(eval(func_str))
              b_data['elements'][el]['doc']['dom_el'][func_data['type']] = eval(func_str);
            }
          } else if (attr_ind === 'src') {
            b_data['elements'][el]['doc']['dom_el'][attr_ind] = d_icons[b_data['elements'][el].attr[attr_ind]];
          } else {
            b_data['elements'][el]['doc']['dom_el'][attr_ind] = b_data['elements'][el].attr[attr_ind];
          }
        }
        for (let style_ind in b_data['elements'][el].style) {
          b_data['elements'][el]['doc']['dom_el'].style[style_ind] = b_data['elements'][el].style[style_ind];
        }
      }
      if (!b_data.button_list) {
        let popup_id_list = '[';
        for (let i in popup_element_list) {
          popup_id_list += '\'' + popup_element_list[i] + '\'';
          if (parseInt(i) !== popup_element_list.length - 1) {
            popup_id_list += ', ';
          } else {
            popup_id_list += ']';
          }
        }
        // console.log(popup_id_list);
        let new_popup_func_str = '(function() {vm.f_mouseOverMenu(' + popup_id_list + ')})';
        b_data['elements']['li']['doc']['dom_el']['onmouseover'] = eval(new_popup_func_str);
      }

      if (b_data.button_list) {
        level += 1;
        let new_popup_id = popup_element.id + '_' + param;
        // console.log('before push ', popup_element_list);
        popup_element_list.push(new_popup_id);
        let popup_id_list = '[';
        for (let i in popup_element_list) {
          popup_id_list += '\'' + popup_element_list[i] + '\'';
          if (parseInt(i) !== popup_element_list.length - 1) {
            popup_id_list += ', ';
          } else {
            popup_id_list += ']';
          }
        }
        // console.log(popup_id_list);
        let new_popup_func_str = '(function() {vm.f_mouseOverMenu(' + popup_id_list + ')})';
        b_data['elements']['li']['doc']['dom_el']['onmouseover'] = eval(new_popup_func_str);
        // console.log(popup_id_list);
        // console.log(b_data.button_list);
        let new_popup_element = document.createElement('div');
        new_popup_element.id = new_popup_id;
        new_popup_element.classList.add("row-right-click");
        new_popup_element.style.display = 'none';
        let new_parent_element = document.createElement('ul');
        new_parent_element.id = parent_element.id + '_' + param;
        let targetPageElement = document.getElementById('case_table');
        // targetPageElement.classList.add("general_scroll_class");
        popup_element_list.splice(popup_element_list.length - 1, 1);
        ClsMenu.f_deeperMenu(vm, b_data.button_list, new_popup_element, new_parent_element, targetPageElement, specific_arguments, d_icons);
        // console.log('popup_element_list before splice', popup_element_list);
        popup_element_list.splice(popup_element_list.length - 1, 1);
        // console.log('popup_element_list after splice', popup_element_list);
        level -= 1;
      }
      for (let aco_el in button_list.appendChildOrder) {
        if (button_list.appendChildOrder[aco_el] !== 'parent_element') {
          let add_el = b_data['elements'][aco_el]['doc']['dom_el'];
          let target_el = b_data['elements'][button_list.appendChildOrder[aco_el]]['doc']['dom_el'];
          target_el.appendChild(add_el);
        } else {
          let add_el = b_data['elements'][aco_el]['doc']['dom_el'];
          parent_element.appendChild(add_el);
        }
      }
    }
    // console.log(ul_el);
    popup_element.appendChild(parent_element);
    // console.log(popup_element);
    targetPageElement.appendChild(popup_element);
    // console.log(targetPageElement);
  }

};

export { ClsMenu };
