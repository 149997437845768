<template>
  <div>
    <b-card no-body>
      <!--       <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
        <img src="@/icon/665778.png" class="img-rounded img-responsive" style="width: 2em;" /> Hızlı Analiz Modu
        <b-button class="pull-right" size="md" variant="white" @click="f_analyzeFastModel()">
          <img src="@/icon/2324511.png" style="width: 2em;" />
        </b-button>
      </b-card-header> -->
      <template v-if="d_columnRule">
        <wdm-rule-a :data_type="d_columnRule.data_type" :option_data="d_columnRule.option_data" :prepare_data="d_columnRule.prepare_data" :query_list="d_columnRule.query_list" :compile_str_array="d_columnRule.compile_str_array" :compile_str_date="d_columnRule.compile_str_date" :wdmr_change="d_columnRule.wdmr_change" :p_wdmList="d_columnRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_columnRule.wps_data" :p_wdmRuleAsettings="d_columnRule.wra_settings" :p_fastAnalyzeMode="d_fastAnalyzeModeOperation" :compile_str_text="d_columnRule.compile_str_text"></wdm-rule-a>
      </template>
      <b-tabs>
        <b-tab @click="d_selectedTab = 'analyze_mode'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;">
            <span>Analiz Modu</span>
          </template>
          <template v-if="d_selectedTab === 'analyze_mode'">
            <b-card no-body :class="[d_fullScreen ?'full-screen-mode' :'normal-mode']">
              <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
                <b-row>
                  <b-col v-if="!device.isMobile" cols="5"></b-col>
                  <b-col :cols="device.isMobile ? 7 : 2" style="text-align: center;">
                    <b-button size="md" variant="white" @click="f_analyzeFastModel()" style="width: 100%;">
                      <img src="@/icon/2324511.png" style="width: 2em;" /> analiz
                    </b-button>
                  </b-col>
                  <b-col cols="5">
                    <b-button v-if="!device.isMobile" class="pull-right" size="md" :variant="d_fullScreen ?'warning' :'secondary'" :style="d_fullScreen ?'margin-right: 5px; padding: 0px; border: solid 2px green;' :'margin-right: 5px; padding: 0px;'" @click="d_fullScreen ? d_fullScreen = false : d_fullScreen = true">
                      <img src="@/icon/629153.png" :title="$t('wdm16.6589')" style="width: 2em;" />
                    </b-button>
                    <b-button class="pull-right" size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="d_fastAnalyzeModeSettings.mode === 'selected_column' ? d_fastAnalyzeModeSettings.mode = 'cross_columns' : d_fastAnalyzeModeSettings.mode = 'selected_column'">
                      <img v-if="d_fastAnalyzeModeSettings.mode === 'cross_columns'" src="@/icon/104931.png" title="Karşılaştırmalı" style="width: 2em;" />
                      <img v-if="d_fastAnalyzeModeSettings.mode === 'selected_column'" src="@/icon/3134186.png" title="Tek Değişkenli Analiz" style="width: 2em;" />
                    </b-button>
                    <b-button v-if="!device.isMobile" class="pull-right" size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="d_tableMode === 'normal' ? d_tableMode = 'all_rows' : d_tableMode = 'normal'">
                      <img v-if="d_tableMode === 'normal'" src="@/icon/1936797.png" title="Normal Mod" style="width: 2em;" />
                      <img v-if="d_tableMode === 'all_rows'" src="@/icon/1319596.png" title="Satır Modu" style="width: 2em;" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-row v-if="d_tableMode === 'normal'">
                <b-col cols="12">
                  <div :style="f_calculateStyleVariableManage()">
                    <table key="fast_analyze_table" v-if="d_fastAnalyzeModeSettings.table.length > 0">
                      <thead>
                        <tr>
                          <th style="position: sticky; z-index: 1000; top: 0; text-align: center;">
                            <img src="@/icon/2653039.png" class="img-rounded img-responsive" style="width: 3em;" />
                            <!-- <b-button size="md" variant="secondary" @click="f_analyzeFastModel()">
                        <img src="@/icon/2324511.png" style="width: 2em;" /> analiz
                      </b-button> -->
                          </th>
                          <template v-for="(col, col_ind) in d_fastAnalyzeModeSettings.table[0]">
                            <th :key="'th_' + col_ind.toString()" :class="[f_calculateThClass(col_ind)]" @mouseover="d_hoverTh = col_ind" @mouseleave="d_hoverTh = ''">
                              <template v-if="col_ind === 0 && d_fastAnalyzeModeSettings.mode === 'cross_columns'">
                                <div style="width: 100%; text-align: center;">
                                  <img src="@/icon/104931.png" style="width: 4em;" />
                                </div>
                              </template>
                              <template v-if="col_ind !== 0 && d_fastAnalyzeModeSettings.mode === 'cross_columns'">
                                <b-input-group>
                                  <v-select v-model="col.selected" :options="d_columnValueLabelList" :style="d_hoverTh === col_ind ? 'width: 85%;' : 'width: 100%;' "></v-select>
                                  <b-input-group-append v-if="d_hoverTh === col_ind">
                                    <b-button class="pull-right" size="md" variant="danger" @click="f_deleteColumnFastModel(col_ind)">-</b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </template>
                            </th>
                          </template>
                          <th v-if="d_fastAnalyzeModeSettings.mode === 'cross_columns'">
                            <b-button size="md" variant="secondary" style="height: 100%; margin-left: 10px;" @click="f_addNewColumn()">+</b-button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(row, row_ind) in d_fastAnalyzeModeSettings.table">
                          <tr v-if="row_ind !== 0" style="border-bottom: solid 1px #c1c7cc;">
                            <td style="position: sticky; left: 0; width: 75px; cursor: pointer;" @mouseover="d_hoverTd = row_ind.toString()" @mouseleave="d_hoverTd = ''">
                              <b-button v-if="d_hoverTd === row_ind.toString()" size="sm" variant="danger" @click="f_deleteRowFastModel(row_ind)">-</b-button>
                              <span class="pull-right">{{ row_ind }}</span>
                            </td>
                            <template v-for="(col, col_ind) in row">
                              <td :key="'td_' + col_ind.toString() + row_ind.toString()" class="td-class" @mouseover="d_hoverTd = col_ind.toString() + row_ind.toString()" @mouseleave="d_hoverTd = ''">
                                <template v-if="col_ind === 0">
                                  <v-select v-model="col.selected" :options="d_columnValueLabelList" style="min-width: 150px;"></v-select>
                                </template>
                                <template v-else>
                                  <template v-for="(ana, ana_ind) in col.analyze_list">
                                    <b-row @mouseover="d_hoverTest = col_ind.toString() + row_ind.toString() + ana_ind.toString()" @mouseleave="d_hoverTest = ''">
                                      <b-col cols="12">
                                        <div>
                                          Analiz - {{ ana_ind + 1 }} =>
                                          <select v-model="ana.test" style="background-color: transparent; padding: 3px; margin: 3px; color: green;">
                                            <option v-for="(test, test_ind) in d_testList[d_fastAnalyzeModeSettings.mode]" :value="test">{{ test.label }}</option>
                                          </select>
                                          <b-button v-if="d_hoverTest === col_ind.toString() + row_ind.toString() + ana_ind.toString() && col.analyze_list.length > 1" size="sm" variant="danger" @click="col.analyze_list.splice(ana_ind, 1); $forceUpdate();">-</b-button>
                                        </div>
                                        <template v-if="ana.results">
                                          <polestar-result :d_selectedWdm73Key="d_selectedWdm73Key" :d_selectedWdm73Data="d_selectedWdm73Data" :p_selectedWdm72Results="ana.results" :column_list="column_list" :option_data="option_data" :p_selectedProjectData="p_selectedProjectData" :p_mode="'text'"></polestar-result>
                                        </template>
                                      </b-col>
                                    </b-row>
                                  </template>
                                  <template v-if="col.results">
                                    <polestar-result :d_selectedWdm73Key="d_selectedWdm73Key" :d_selectedWdm73Data="d_selectedWdm73Data" :p_selectedWdm72Results="col.results" :column_list="column_list" :option_data="option_data" :p_selectedProjectData="p_selectedProjectData" :p_mode="'text'"></polestar-result>
                                  </template>
                                  <div v-if="d_hoverTd === col_ind.toString() + row_ind.toString()" style="height: 20px;">
                                    <b-button class="pull-right" size="sm" variant="primary" @click="col.analyze_list.push({'test': { 'label': 'Genel', 'value': '' }}); $forceUpdate();">+</b-button>
                                  </div>
                                  <div v-else style="height: 20px;">
                                  </div>
                                </template>
                              </td>
                            </template>
                            <td></td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                    <b-row>
                      <b-col cols="12">
                        <b-button size="sm" style="width: 75px;" variant="secondary" @click="f_addNewRowFastAnalyze()">+</b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
              <template v-if="d_tableMode === 'all_rows'">
                <b-row>
                  <b-col cols="12">
                    <template v-for="(row, row_ind) in d_fastAnalyzeModeSettings.table">
                      <template v-for="(col, col_ind) in row">
                        <template v-for="(ana, ana_ind) in col.analyze_list">
                          <b-row style="margin: 3px;">
                            <b-col sm="12" md="12" lg="1">
                              <template v-if="d_fastAnalyzeModeSettings.table.length > 2">
                                <b-button size="sm" variant="danger" @click="f_deleteRowFastModel(row_ind)">-</b-button>
                                <span class="pull-right">{{ row_ind }}</span>
                              </template>
                            </b-col>
                            <b-col sm="12" md="12" lg="3">
                              <v-select v-model="row[0].selected" :options="d_columnValueLabelList"></v-select>
                              <template v-if="d_fastAnalyzeModeSettings.mode === 'cross_columns'">
                                <v-select v-model="d_fastAnalyzeModeSettings.table[0][col_ind].selected" :options="d_columnValueLabelList"></v-select>
                              </template>
                            </b-col>
                            <b-col sm="12" md="12" lg="8">
                              <b-row>
                                <b-col cols="12">
                                  <b-row style="margin: 0px;">
                                    <b-col v-if="col.analyze_list.length > 1" cols="3" style="padding: 3px;">analiz-{{ ana_ind + 1 }}</b-col>
                                    <b-col :cols="col.analyze_list.length > 1 ? 7 : 12" style="padding: 3px;">
                                      <select v-model="ana.test" style="background-color: transparent; padding: 3px; color: green; border: 1px solid rgba(158, 193, 179, 0.3); width: 100%;">
                                        <option v-for="(test, test_ind) in d_testList[d_fastAnalyzeModeSettings.mode]" :value="test">{{ test.label }}</option>
                                      </select>
                                    </b-col>
                                    <b-col v-if="col.analyze_list.length > 1" cols="2" style="padding: 3px;">
                                      <b-button v-if="col.analyze_list.length > 1" size="sm" variant="danger" @click="col.analyze_list.splice(ana_ind, 1); $forceUpdate();">-</b-button>
                                    </b-col>
                                  </b-row>
                                  <template v-if="ana.results">
                                    <polestar-result :d_selectedWdm73Key="d_selectedWdm73Key" :d_selectedWdm73Data="d_selectedWdm73Data" :p_selectedWdm72Results="ana.results" :column_list="column_list" :option_data="option_data" :p_selectedProjectData="p_selectedProjectData" :p_mode="'text'"></polestar-result>
                                  </template>
                                </b-col>
                              </b-row>
                              <template v-if="col.results">
                                <polestar-result :d_selectedWdm73Key="d_selectedWdm73Key" :d_selectedWdm73Data="d_selectedWdm73Data" :p_selectedWdm72Results="col.results" :column_list="column_list" :option_data="option_data" :p_selectedProjectData="p_selectedProjectData" :p_mode="'text'"></polestar-result>
                              </template>
                            </b-col>
                          </b-row>
                        </template>
                        <div v-if="col_ind > 0 && row_ind > 0" style="height: 20px;">
                          <b-button class="pull-right" size="sm" variant="primary" @click="col.analyze_list.push({'test': { 'label': 'Genel', 'value': '' }}); $forceUpdate();">+</b-button>
                        </div>
                      </template>
                    </template>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-button size="sm" style="width: 75px;" variant="secondary" @click="f_addNewRowFastAnalyze()">+</b-button>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </template>
        </b-tab>
        <b-tab @click="d_selectedTab = 'results_mode'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;">
            <span>Tüm Sonuçlar Ekranı</span>
          </template>
          <template v-if="d_selectedTab === 'results_mode'">
            <b-card no-body :class="[d_fullScreenResultsMode ?'full-screen-mode' :'normal-mode']">
              <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
                <b-row>
                  <b-col cols="10"></b-col>
                  <b-col cols="2"> </b-col>
                </b-row>
              </b-card-header>
              <b-row style="margin: 0px;">
                <b-col cols="12">
                  <polestar-result :d_selectedWdm73Key="d_selectedWdm73Key" :d_selectedWdm73Data="d_selectedWdm73Data" :p_selectedWdm72Results="d_resultsTogether" :column_list="column_list" :option_data="option_data" :p_selectedProjectData="p_selectedProjectData"></polestar-result>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import OptionService from '@/services/option';
import vSelect from 'vue-select';
import PoleStarService from '@/services/polestar';
import { ClsRule } from '@/services/public/rule';
import { ClsPolestar } from "@/services/public/polestar";
import { mapGetters } from 'vuex';
// import moment from 'moment';
import {
  default as WdmRuleA
} from '@/components/widgets/WdmRuleA';
import {
  default as PolestarResult
} from "@/components/widgets/PolestarResult";
import {
  default as Modal
} from '@/components/widgets/Modal';

export default {
  name: 'PolestarFastAnalyze',
  components: {
    Modal,
    WdmRuleA,
    PolestarResult,
    vSelect
  },
  props: {
    p_mode: {
      type: String,
      required: false
    },
    mother_children: {
      type: Object,
      required: true
    },
    column_list: {
      type: Array,
      required: true
    },
    p_selectedProjectData: {
      type: Object,
      required: true
    },
    d_selectedWdm73Data: {
      type: Object,
      required: false
    },
    option_data: {
      type: Object,
      required: false
    },
    d_selectedWdm73Key: {
      type: String,
      required: false
    },
    d_selectedWdm72Key: {
      type: String,
      required: false
    },
    p_isPolestarColumn: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      device: 'device'
    })
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (!this.option_data.wdm72) {
      let query = 'data_type=wdm72&location=wisdom';
      OptionService.get_last_wdm_of_this_type(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.option_data['wdm72'] = resp.data.result;
          } else {
            console.log('errror', resp.data.message);
          }
        });
    }
    /*
      if (this.device.isMobile) {
        this.d_tableMode = 'all_rows';
      } else {
        this.d_tableMode = 'normal';
      }
    */
  },
  mounted: function () {
    // this.d_userProjectPermissions = ClsPolestar.f_prepareUserPermissionsForThisProject(this.p_selectedProjectData, this.user.username);
    this.f_fastAnalyzeMode();
  },
  data () {
    return {
      d_resultsTogether: [],
      d_selectedTab: 'analyze_mode',
      d_userProjectPermissions: [],
      d_fastAnalyzeModeOperation: true,
      d_tableMode: 'all_rows', // normal
      d_fullScreenResultsMode: false,
      d_fullScreen: false,
      d_hoverTest: '',
      d_hoverTd: '',
      d_hoverTh: '',
      d_testList: {
        'cross_columns': [
          { 'label': 'Genel', 'value': '' },
          { 'label': 'Kikare', 'value': 'chisquare' },
          { 'label': 'Fisher Exact', 'value': 'fisherexact' },
          { 'label': 'Oneway ANOVA', 'value': 'oneway_anova' },
          { 'label': 'Pearson korelasyon', 'value': 'pearson_correlation' }
        ],
        'selected_column': [
          { 'label': 'Ortalama', 'value': 'mean' },
          { 'label': 'Sıklık', 'value': 'frequency' },
          { 'label': 'Minimum', 'value': 'min' },
          { 'label': 'Maksimum', 'value': 'max' },
          { 'label': 'Aykırı Değer (z-index)', 'value': 'outliers_zindex' },
          { 'label': 'Aykırı Değer (interquartile-range)', 'value': 'outliers_interquartile_range' },
          { 'label': 'Veri dağılımı (eğim, skewness)', 'value': 'skewness' },
          { 'label': 'Toplama', 'value': 'sum' },
          { 'label': 'Ortanca', 'value': 'median' }
        ]
      },
      d_columnValueLabelList: [],
      d_fastAnalyzeModeSettings: {
        'show': false,
        'table': [],
        'mode': 'selected_column' // selected_column, cross_columns
      },
      d_columnRule: {
        'date_ability': '',
        'wdmr_change': { 'rule': 0 },
        'data_type': '',
        'option_data': {},
        'prepare_data': {},
        'query_list': [],
        'compile_str_date': [],
        'compile_str_text': [],
        'compile_str_array': [],
        'wra_settings': {
          'mode': 'param',
          'options': [
            { 'label': 'WDM & QUERY', 'value': 'wdm_query' },
            { 'label': 'PARAM', 'value': 'param' },
            { 'label': 'PARAM & QUERY', 'value': 'param_query' }
          ]
        },
        'wps_data': [{
          'operation': '',
          'selected_value': '',
          'loc': [],
          'val_list': [],
          'selected_wdm': '',
          'd_level': 0,
          'd_level_count': 0,
          'significant': 'true'
        }],
        'wdm_list': []
      }
    }
  },
  methods: {
    f_calculateStyleVariableManage: function () {
      let height = '';
      let style = '';
      if (this.d_fullScreen) {
        height = 'height: ' + (window.innerHeight - 75).toString() + 'px;';
      } else if (this.p_mode && this.p_mode === 'talk') {
        height = 'height: ' + (window.innerHeight - 200).toString() + 'px;';
      } else {
        height = 'height: ' + (window.innerHeight - 300).toString() + 'px;';
      }
      style = 'overflow-y: auto; overflow-x: auto; margin-bottom: 0px; padding: 5px; font-size: 10px;' + height;
      return style;
    },
    f_calculateThClass: function (col_ind) {
      if (this.d_fastAnalyzeModeSettings.mode === 'cross_columns') {
        if (col_ind === 0) {} else {
          return 'cls_th_variable_cross_columns';
        }
      } else {
        if (col_ind === 0) {} else {
          return 'cls_th_variable_selected_column';
        }
      }
    },
    f_fastAnalyzeMode: function () {
      for (let i in this.column_list) {
        let x = { 'label': this.column_list[i].label, 'value': i.toString(), 'param_type': this.column_list[i].param_type };
        this.d_columnValueLabelList.push(x);
      }
      this.d_fastAnalyzeModeSettings.table = [
        [{}, { 'selected': '' }],
        [{ 'selected': '' }, { 'analyze_list': [{ 'test': { 'label': 'Ortalama', 'value': 'mean' } }] }]
      ];
      ClsPolestar.f_prepareColumnRuleOptionData(this.d_columnRule, this.column_list);
    },
    f_deleteRowFastModel: function (row_ind) {
      this.d_fastAnalyzeModeSettings.table.splice(row_ind, 1);
      this.$forceUpdate();
    },
    f_deleteColumnFastModel: function (col_ind) {
      for (let i in this.d_fastAnalyzeModeSettings.table) {
        this.d_fastAnalyzeModeSettings.table[i].splice(col_ind, 1);
      }
      this.$forceUpdate();
    },
    f_analyzeFastModel: function () {
      let parenthesis_control = ClsRule.f_controlParanthesisDesign(this.d_columnRule.compile_str_array);
      // console.log('parenthesis_control : ', parenthesis_control);
      if (parenthesis_control) {
        this.d_resultsTogether = [];
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analiz başlatıldı. Lütfen bekleyiniz.' } });
        // When we write our code with mode param, we have to convert it to query_list format to make a filter.
        if (this.d_columnRule.wra_settings.mode === 'param') {
          ClsRule.f_paramToQuery(this.d_columnRule.query_list, this.d_columnRule.compile_str_array, this.d_columnRule.wps_data);
        }
        this.f_createWdm72TmpData();
        let data = {
          'mode': this.d_fastAnalyzeModeSettings.mode,
          'wdm3_id': this.p_selectedProjectData.id,
          'rule': {
            'query_list': this.d_columnRule.query_list,
            'compile_str_array': this.d_columnRule.compile_str_array,
            'compile_str_text': this.d_columnRule.compile_str_text,
            'compile_str_date': this.d_columnRule.compile_str_date
          },
          'table': this.d_fastAnalyzeModeSettings.table
        };
        PoleStarService.analyze_fast_model(data)
          .then(resp => {
            // console.log(resp.data.result);
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              this.d_fastAnalyzeModeSettings.table = resp.data.result.table_data;
              for (let row_ind in this.d_fastAnalyzeModeSettings.table) {
                for (let col_ind in this.d_fastAnalyzeModeSettings.table[row_ind]) {
                  for (let r_ind in this.d_fastAnalyzeModeSettings.table[row_ind][col_ind].results) {
                    let res_data = this.d_fastAnalyzeModeSettings.table[row_ind][col_ind].results[r_ind];
                    this.d_resultsTogether.push(res_data);
                  }
                  for (let ana_ind in this.d_fastAnalyzeModeSettings.table[row_ind][col_ind].analyze_list) {
                    for (let r_ind in this.d_fastAnalyzeModeSettings.table[row_ind][col_ind].analyze_list[ana_ind].results) {
                      let res_data = this.d_fastAnalyzeModeSettings.table[row_ind][col_ind].analyze_list[ana_ind].results[r_ind];
                      this.d_resultsTogether.push(res_data);
                    }
                  }
                }
              }
            } else {
              alert('Error :' + resp.data.message)
            }
          });
      } else {
        let msg = 'Lütfen parentezlerinizi kontrol ediniz.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Algoritma kuralı oluşturma işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_createWdm72TmpData: function () {
      let rule_algoritm = this.d_columnRule;
      let table_data = this.d_fastAnalyzeModeSettings.table;
      if (this.d_fastAnalyzeModeSettings.mode === 'cross_columns') {
        for (let r in table_data) {
          if (parseInt(r) > 0) {
            for (let c in table_data[0]) {
              if (parseInt(c) > 0) {
                if (table_data[r][0]['selected'] && table_data[0][c]['selected']) {
                  let pre_data = this.f_startWdm72Preparing();
                  pre_data['data']['general']['analyze_type'] = { 'val': { 'value': 'cross_columns', 'label': 'karşılaştırma' } };
                  let rc_data = table_data[r][0]['selected'];
                  let cc_data = table_data[0][c]['selected'];
                  pre_data['data']['general']['selected_row_parameters'] = {};
                  pre_data['data']['general']['selected_column_parameters'] = {};
                  pre_data['data']['general']['usage_of_comparison_tests'] = {};
                  pre_data['data']['general']['selected_row_parameters'][rc_data.value] = {
                    'name': { 'label': rc_data.label, 'value': rc_data.value },
                    'detail': {
                      'column_param_type_usage': { 'val': { 'value': 'auto', 'label': 'Otomatik' } },
                      'column_option_group_type': { 'val': { 'value': 'standard_mode', 'label': 'standart mod (hücre içerisindeki görünüm modunda)' } }
                    }
                  };
                  pre_data['data']['general']['selected_column_parameters'][cc_data.value] = {
                    'name': { 'label': cc_data.label, 'value': cc_data.value },
                    'detail': {
                      'column_param_type_usage': { 'val': { 'value': 'auto', 'label': 'Otomatik' } },
                      'column_option_group_type': { 'val': { 'value': 'standard_mode', 'label': 'standart mod (hücre içerisindeki görünüm modunda)' } }
                    }
                  };
                  if (table_data[r][c].analyze_list.length > 0) {
                    for (let t in table_data[r][c].analyze_list) {
                      let test = table_data[r][c].analyze_list[t].test;
                      if (test.value !== '') {
                        if (['oneway_anova'].indexOf(test.value) !== -1) {
                          if (!pre_data['data']['general']['selected_categorical_to_nominal']) {
                            pre_data['data']['general']['selected_categorical_to_nominal'] = {}
                          }
                          pre_data['data']['general']['selected_categorical_to_nominal'][test.value] = { 'detail': {}, 'name': test };
                        }
                        if (['chisquare', 'fisherexact'].indexOf(test.value) !== -1) {
                          console.log('2');
                          if (!pre_data['data']['general']['selected_categorical_to_categorical']) {
                            pre_data['data']['general']['selected_categorical_to_categorical'] = {}
                          }
                          pre_data['data']['general']['selected_categorical_to_categorical'][test.value] = { 'detail': {}, 'name': test };
                        }
                        if (['spearman_correlation', 'pearson_correlation', 'kendall_correlation'].indexOf(test.value) !== -1) {
                          if (!pre_data['data']['general']['selected_nominal_to_nominal']) {
                            pre_data['data']['general']['selected_nominal_to_nominal'] = {}
                          }
                          pre_data['data']['general']['selected_nominal_to_nominal'][test.value] = { 'detail': {}, 'name': test };
                        }
                      }
                    }
                  }
                  table_data[r][c]['wdm72_data'] = JSON.parse(JSON.stringify(pre_data));
                }
              }
            }
          }
        }
      } else if (this.d_fastAnalyzeModeSettings.mode === 'selected_column') {
        for (let r in table_data) {
          if (parseInt(r) > 0) {
            for (let c in table_data[0]) {
              if (parseInt(c) > 0) {
                // c maximum can be 1 in selected_column mode.
                if (table_data[r][0]['selected']) {
                  let rc_data = table_data[r][0]['selected'];
                  let pre_data = this.f_startWdm72Preparing();
                  for (let t in table_data[r][c].analyze_list) {
                    let test = table_data[r][c].analyze_list[t].test;
                    pre_data['data']['general']['analyze_type'] = {
                      'val': test
                    };
                    pre_data['data']['general']['selected_columns'] = {};

                    pre_data['data']['general']['selected_columns'][rc_data.value] = {
                      'name': { 'label': rc_data.label, 'value': rc_data.value },
                      'detail': {
                        'param_type_usage': { 'val': { 'value': 'auto', 'label': 'Otomatik' } },
                        'option_group_type': { 'val': { 'value': 'standard_mode', 'label': 'standart mod (hücre içerisindeki görünüm modunda)' } },
                        'selected_column_timeline_mode': { 'val': { 'value': 'no', 'label': 'hayır' } }
                      }
                    };
                    table_data[r][c].analyze_list[t]['wdm72_data'] = JSON.parse(JSON.stringify(pre_data));
                  }
                }
              }
            }
          }
        }
      }
    },
    f_startWdm72Preparing: function () {
      let pre_data = {
        'id': 'new',
        'data': {},
        'type': 'wdm72',
        'reference_wdm': {},
        'wdm3_id': this.p_selectedProjectData.id,
        'rule_algoritm': {
          'query_list': this.d_columnRule.query_list,
          'compile_str_array': this.d_columnRule.compile_str_array,
          'compile_str_text': this.d_columnRule.compile_str_text,
          'compile_str_date': this.d_columnRule.compile_str_date
        }
      };
      pre_data['reference_wdm']['version'] = this.option_data['wdm72']['version'];
      pre_data['reference_wdm']['owner_type'] = this.option_data['wdm72']['owner_type'];
      if (this.option_data['wdm72']['owner_type'] === 'wisdom') {
        pre_data['reference_wdm']['key'] = 'wdm_wisdom_wdm72_v' + this.option_data['wdm72'].version;
      }
      for (let i in this.option_data['wdm72'].param_group.param_order) {
        pre_data['data'][this.option_data['wdm72'].param_group.param_order[i]] = {};
      }
      pre_data['data']['general']['wdm3_name'] = { 'val': this.p_selectedProjectData.data.general.name.val };
      pre_data['data']['general']['wdm3_key'] = { 'val': 'wdm3_' + this.p_selectedProjectData.id };
      pre_data['data']['general']['analyze_time_type'] = { 'val': { 'value': 'manuel', 'label': 'manuel' } };
      return pre_data;
    },
    f_addNewColumn: function () {
      for (let r in this.d_fastAnalyzeModeSettings.table) {
        if (parseInt(r) === 0) {
          this.d_fastAnalyzeModeSettings.table[r].push({ 'selected': '' });
        } else {
          if (this.d_fastAnalyzeModeSettings.mode === 'cross_columns') {
            this.d_fastAnalyzeModeSettings.table[r].push({ 'analyze_list': [{ 'test': { 'label': 'Genel', 'value': '' } }] });
          } else if (this.d_fastAnalyzeModeSettings.mode === 'selected_column') {
            let tst = '';
            if (['select', 'object_list_options', 'checkbox', 'radiogroup'].indexOf(this.d_fastAnalyzeModeSettings.table[r][0].param_type) !== -1) {
              tst = { 'label': 'Sıklık', 'value': 'frequency' };
            } else {
              tst = { 'label': 'Ortalama', 'value': 'mean' };
            }
            this.d_fastAnalyzeModeSettings.table[r].push({ 'analyze_list': [{ 'test': tst }] });
          }
        }
      }
      this.$forceUpdate();
    },
    f_addNewRowFastAnalyze: function () {
      let new_row = [];
      if (this.d_fastAnalyzeModeSettings.table[0] && this.d_fastAnalyzeModeSettings.table[0].length > 0) {
        for (let i in this.d_fastAnalyzeModeSettings.table[0]) {
          if (parseInt(i) === 0) {
            new_row.push({ 'selected': '' });
          } else {
            if (this.d_fastAnalyzeModeSettings.mode === 'cross_columns') {
              new_row.push({ 'analyze_list': [{ 'test': { 'label': 'Genel', 'value': '' } }] });
            } else if (this.d_fastAnalyzeModeSettings.mode === 'selected_column') {
              new_row.push({ 'analyze_list': [{ 'test': { 'label': 'Ortalama', 'value': 'mean' } }] });
            }
          }
        }
      }
      this.d_fastAnalyzeModeSettings.table.push(new_row);
      this.$forceUpdate();
    }
  },
  watch: {
    'd_fastAnalyzeModeSettings.mode': function () {
      if (this.d_fastAnalyzeModeSettings.mode === 'selected_column') {
        for (let r in this.d_fastAnalyzeModeSettings.table) {
          let tst = '';
          if (['select', 'object_list_options', 'checkbox', 'radiogroup'].indexOf(this.d_fastAnalyzeModeSettings.table[r][0].param_type) !== -1) {
            tst = { 'label': 'Sıklık', 'value': 'frequency' };
          } else {
            tst = { 'label': 'Ortalama', 'value': 'mean' };
          }
          this.d_fastAnalyzeModeSettings.table[r][1] = { 'analyze_list': [{ 'test': tst }] };
          for (let c in this.d_fastAnalyzeModeSettings.table[r]) {
            if (parseInt(c) >= 2) {
              this.d_fastAnalyzeModeSettings.table[r].splice(c, 1);
            }
          }
        }
        this.d_fastAnalyzeModeSettings.table[0][1] = {};
      } else if (this.d_fastAnalyzeModeSettings.mode === 'cross_columns') {
        this.d_fastAnalyzeModeSettings.table[0][1] = { 'selected': '' };
        for (let r in this.d_fastAnalyzeModeSettings.table) {
          for (let c in this.d_fastAnalyzeModeSettings.table[r]) {
            if (parseInt(r) > 0 && parseInt(c) === 1) {
              this.d_fastAnalyzeModeSettings.table[r][c] = { 'analyze_list': [{ 'test': { 'label': 'Genel', 'value': '' } }] };
            }
          }
        }
      }
    }
  }
};

</script>

<style type="text/css">
canvas {
  background-color: #fff;
}

.td-class {
  background-color: transparent;
  min-height: 50px;
  width: 200px;
}

.normal-mode {}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

.cls_th_variable_selected_column {
  position: sticky;
  z-index: 1000;
  top: 0;
  white-space: nowrap;
  /*border-right: solid 1px #c3d8d8;*/
  /*border-bottom: solid 1px #c3d8d8;*/
  /*background-color: #ececec;*/
  min-width: 700px;
}

.cls_th_variable_cross_columns {
  position: sticky;
  z-index: 1000;
  top: 0;
  white-space: nowrap;
  /*border-right: solid 1px #c3d8d8;*/
  /*border-bottom: solid 1px #c3d8d8;*/
  /*background-color: #ececec;*/
  min-width: 250px;
}

</style>

