var right_click_buttons = {
  'polestar_left_row_head': {
    'appendChildOrder': { 'img': 'li', 'a': 'li', 'li': 'parent_element' },
    'parent_order': ['img', 'a', 'li'],
    // 'param_order': ['patient_follow', 'delete_row', 'add_row_up', 'add_row_down', 'patient_relation', 'get_patient_wdmr_by_deleting_manuel_edit', 'get_patient_wdmr_by_notdeleting_manuel_edit', 'decision_making_packages', 'sms'],
    'param_order': ['delete_row', 'add_row_up', 'add_row_down', 'decision_making_packages'],
    'parameters': {
      'patient_relation': {
        'require': [],
        'elements': {
          'li': {
            'attr': {
              'functions': [{
                'type': 'onclick',
                'arguments': [{
                  'type': 'var',
                  'name': 'row_ind'
                }],
                'name': 'f_patientCombine'
              }]
            }
          },
          'a': {
            'attr': {
              'innerHTML': 'Hasta İle İlişkilendirme',
              'href': 'javascript:'
            },
            'style': {
              'color': 'black'
            }
          },
          'img': {
            'attr': {
              'src': '969174'
            },
            'style': {
              'width': '20px',
              'margin-right': '5px'
            }
          }
        }
      },
      'get_patient_wdmr_by_deleting_manuel_edit': {
        'require': [{
          'name': 'patient_id',
          'res': 'true'
        }, {
          'name': 'd_eligibleForCaseSearch',
          'res': 'true'
        }],
        'elements': {
          'li': {
            'attr': {
              'functions': [{
                'type': 'onclick',
                'arguments': [{
                  'type': 'var',
                  'name': 'row_ind'
                }, {
                  'type': 'string',
                  'name': 'delete_manuel_edit'
                }],
                'name': 'f_updatePatientWdmrCells'
              }]
            }
          },
          'a': {
            'attr': {
              'innerHTML': 'Hasta Wdmr Kayıtlarını Güncelle (manuel düzenlemeleri sil)',
              'href': 'javascript:'
            },
            'style': {
              'color': 'black'
            }
          },
          'img': {
            'attr': {
              'src': '42729'
            },
            'style': {
              'width': '20px',
              'margin-right': '5px'
            }
          }
        }
      },
      'get_patient_wdmr_by_notdeleting_manuel_edit': {
        'require': [{
          'name': 'patient_id',
          'res': 'true'
        }, {
          'name': 'd_eligibleForCaseSearch',
          'res': 'true'
        }],
        'elements': {
          'li': {
            'attr': {
              'functions': [{
                'type': 'onclick',
                'arguments': [{
                  'type': 'var',
                  'name': 'row_ind'
                }, {
                  'type': 'string',
                  'name': 'not_delete_manuel_edit'
                }],
                'name': 'f_updatePatientWdmrCells'
              }]
            }
          },
          'a': {
            'attr': {
              'innerHTML': 'Hasta Wdmr Kayıtlarını Güncelle (manuel düzenlemeleri silmeden)',
              'href': 'javascript:'
            },
            'style': {
              'color': 'black'
            }
          },
          'img': {
            'attr': {
              'src': '42729'
            },
            'style': {
              'width': '20px',
              'margin-right': '5px'
            }
          }
        }
      },
      'patient_follow': {
        'require': [{
          'name': 'patient_id',
          'res': 'true'
        }],
        'elements': {
          'li': {
            'attr': {
              'functions': [{
                'type': 'onclick',
                'arguments': [{
                  'type': 'var',
                  'name': 'patient_id'
                }],
                'name': 'f_getPatientData'
              }]
            }
          },
          'a': {
            'attr': {
              'innerHTML': 'Hasta Takip Bilgilerine Gitmek İçin Tıklayınız',
              'href': 'javascript:'
            },
            'style': {
              'color': 'black'
            }
          },
          'img': {
            'attr': {
              'src': '969174'
            },
            'style': {
              'width': '20px',
              'margin-right': '5px'
            }
          }
        }
      },
      'delete_row': {
        'require': [],
        'elements': {
          'li': {
            'attr': {
              'functions': [{
                'type': 'onclick',
                'arguments': [{
                  'type': 'var',
                  'name': 'row_ind'
                }],
                'name': 'f_deleteDatasetRow'
              }]
            }
          },
          'a': {
            'attr': {
              'innerHTML': 'Satırı Sil',
              'href': 'javascript:'
            },
            'style': {
              'color': 'black'
            }
          },
          'img': {
            'attr': {
              'src': '2960590'
            },
            'style': {
              'width': '20px',
              'margin-right': '5px'
            }
          }
        }
      },
      'add_row_up': {
        'require': [],
        'elements': {
          'li': {
            'attr': {
              'functions': [{
                'type': 'onclick',
                'arguments': [{
                  'type': 'var',
                  'name': 'row_ind'
                }, {
                  'type': 'string',
                  'name': 'up'
                }],
                'name': 'f_addNewRow'
              }]
            }
          },
          'a': {
            'attr': {
              'innerHTML': 'Üstüne Satır Ekle',
              'href': 'javascript:'
            },
            'style': {
              'color': 'black'
            }
          },
          'img': {
            'attr': {
              'src': '453632'
            },
            'style': {
              'width': '20px',
              'margin-right': '5px'
            }
          }
        }
      },
      'add_row_down': {
        'require': [],
        'elements': {
          'li': {
            'attr': {
              'functions': [{
                'type': 'onclick',
                'arguments': [{
                  'type': 'var',
                  'name': 'row_ind'
                }, {
                  'type': 'string',
                  'name': 'down'
                }],
                'name': 'f_addNewRow'
              }]
            }
          },
          'a': {
            'attr': {
              'innerHTML': 'Altına Satır Ekle',
              'href': 'javascript:'
            },
            'style': {
              'color': 'black'
            }
          },
          'img': {
            'attr': {
              'src': '453632'
            },
            'style': {
              'width': '20px',
              'margin-right': '5px'
            }
          }
        }
      },
      'relate_with_hospital_patient': {
        'require': [{
          'name': 'patient_id',
          'res': 'true'
        }],
        'elements': {
          'li': {
            'attr': {
              'functions': [{
                'type': 'onclick',
                'arguments': [{
                  'type': 'var',
                  'name': 'row_ind'
                }],
                'name': 'f_openHospitalPatientRelation'
              }]
            }
          },
          'a': {
            'attr': {
              'innerHTML': 'Hasta ile ilişkilendir',
              'href': 'javascript:'
            },
            'style': {
              'color': 'black'
            }
          },
          'img': {
            'attr': {
              'src': '2162287'
            },
            'style': {
              'width': '20px',
              'margin-right': '5px'
            }
          }
        }
      },
      'decision_making_packages': {
        'require': [{
          'name': 'decision_making_packages',
          'res': 'true'
        }],
        'elements': {
          'li': {
            'attr': {
              'functions': []
            }
          },
          'a': {
            'attr': {
              'innerHTML': 'Karar Destek Paketleri',
              'href': 'javascript:'
            },
            'style': {
              'color': 'black'
            }
          },
          'img': {
            'attr': {
              'src': '1115721'
            },
            'style': {
              'width': '20px',
              'margin-right': '5px'
            }
          }
        }
      },
      'sms': {
        'require': [],
        'elements': {
          'li': {
            'attr': {
              'functions': []
            }
          },
          'a': {
            'attr': {
              'innerHTML': 'Sms',
              'href': 'javascript:'
            },
            'style': {
              'color': 'black'
            }
          },
          'img': {
            'attr': {
              'src': '1115721'
            },
            'style': {
              'width': '20px',
              'margin-right': '5px'
            }
          }
        }
      },
    }
  },
  'polestar_cell': {
    'appendChildOrder': { 'img': 'li', 'a': 'li', 'li': 'parent_element' },
    'parent_order': ['img', 'a', 'li'],
    // 'param_order': ['go_to_hims_inspection', 'care_plan_symptom', 'care_plan', 'symptom'],
    'param_order': [],
    'parameters': {
      'go_to_hims_inspection': {
        'require': [{
          'name': 'patient_id',
          'res': 'true'
        }],
        'elements': {
          'li': {
            'attr': {
              'functions': [{
                'type': 'onclick',
                'arguments': [{
                  'type': 'var',
                  'name': 'patient_id'
                }, {
                  'type': 'var',
                  'name': 'row_ind'
                }, {
                  'type': 'var',
                  'name': 'col_ind'
                }, {
                  'type': 'string',
                  'name': 'go_to_hims_inspection'
                }],
                'name': 'f_analyzePatientInspectionData'
              }]
            }
          },
          'a': {
            'attr': {
              'innerHTML': 'HBYS muayene bilgileri sayfasını görüntüle',
              'href': 'javascript:'
            },
            'style': {
              'color': 'black'
            }
          },
          'img': {
            'attr': {
              'src': '42729'
            },
            'style': {
              'width': '20px',
              'margin-right': '5px'
            }
          }
        }
      },
      'care_plan_symptom': {
        'require': [{
          'name': 'patient_id',
          'res': 'true'
        }],
        'elements': {
          'li': {
            'attr': {
              'functions': [{
                'type': 'onclick',
                'arguments': [{
                  'type': 'var',
                  'name': 'patient_id'
                }, {
                  'type': 'var',
                  'name': 'row_ind'
                }, {
                  'type': 'var',
                  'name': 'col_ind'
                }, {
                  'type': 'string',
                  'name': 'bakım planı:care_plan;semptom:symptom'
                }],
                'name': 'f_analyzePatientInspectionData'
              }]
            }
          },
          'a': {
            'attr': {
              'innerHTML': 'Protokol muayene bilgileri bakım planı ve semptom alanında kolon arama ifadelerini ara',
              'href': 'javascript:'
            },
            'style': {
              'color': 'black'
            }
          },
          'img': {
            'attr': {
              'src': '42729'
            },
            'style': {
              'width': '20px',
              'margin-right': '5px'
            }
          }
        }
      },
      'symptom': {
        'require': [{
          'name': 'patient_id',
          'res': 'true'
        }],
        'elements': {
          'li': {
            'attr': {
              'functions': [{
                'type': 'onclick',
                'arguments': [{
                  'type': 'var',
                  'name': 'patient_id'
                }, {
                  'type': 'var',
                  'name': 'row_ind'
                }, {
                  'type': 'var',
                  'name': 'col_ind'
                }, {
                  'type': 'string',
                  'name': 'semptom:symptom'
                }],
                'name': 'f_analyzePatientInspectionData'
              }]
            }
          },
          'a': {
            'attr': {
              'innerHTML': 'Protokol muayene bilgileri semptom alanında kolon arama ifadelerini ara',
              'href': 'javascript:'
            },
            'style': {
              'color': 'black'
            }
          },
          'img': {
            'attr': {
              'src': '42729'
            },
            'style': {
              'width': '20px',
              'margin-right': '5px'
            }
          }
        }
      },
      'care_plan': {
        'require': [{
          'name': 'patient_id',
          'res': 'true'
        }],
        'elements': {
          'li': {
            'attr': {
              'functions': [{
                'type': 'onclick',
                'arguments': [{
                  'type': 'var',
                  'name': 'patient_id'
                }, {
                  'type': 'var',
                  'name': 'row_ind'
                }, {
                  'type': 'var',
                  'name': 'col_ind'
                }, {
                  'type': 'string',
                  'name': 'bakım planı:care_plan'
                }],
                'name': 'f_analyzePatientInspectionData'
              }]
            }
          },
          'a': {
            'attr': {
              'innerHTML': 'Protokol muayene bilgileri bakım planı alanında kolon arama ifadelerini ara',
              'href': 'javascript:'
            },
            'style': {
              'color': 'black'
            }
          },
          'img': {
            'attr': {
              'src': '42729'
            },
            'style': {
              'width': '20px',
              'margin-right': '5px'
            }
          }
        }
      }
    }
  }
}

export { right_click_buttons as options };
