<template>
  <div>
    <pole-star-wisdom-stats></pole-star-wisdom-stats>
  </div>
</template>

<script>
import store from '@/store';
import PoleStarWisdomStats from '@/components/widgets/PoleStarWisdomStats';
import { mapGetters } from 'vuex';

export default {
  name: 'PoleStarWisdomStatsParent',
  data () {
    return {};
  },
  components: {
    PoleStarWisdomStats
  },
  computed: mapGetters({
    help: 'help'
  }),
  created: function () {},
  mounted () {},
  methods: {},
  watch: {}
};

</script>

<style type="text/css"></style>
