import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  send_row_sms_list (data) {
    return Vue.http.post(API_BASE_URL + 'sms/send_row_sms_list', data);
  },
  add_sms_provider_to_project (data) {
    return Vue.http.post(API_BASE_URL + 'sms/add_sms_provider_to_project', data);
  }
};
