import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  save (data) {
    var id = 'new';
    if (data.id) {
      id = data.id;
    } else {
      data.id = id;
    }
    let url = API_BASE_URL + 'project/save/' + id;
    return Vue.http.put(url, data);
  },
  get_all_projects () {
    return Vue.http.get(API_BASE_URL + 'project/get_all_projects/');
  },
  get_all_project_details () {
    return Vue.http.get(API_BASE_URL + 'project/get_all_project_details/');
  },
  get_project_by_id (uuid) {
    return Vue.http.get(API_BASE_URL + 'project/get_project_by_id/' + uuid);
  },
  get_all_wdm_version_list () {
    return Vue.http.get(API_BASE_URL + 'project/get_all_wdm_version_list/');
  },
  get_patient_file_system_list (query) {
    return Vue.http.get(API_BASE_URL + 'project/get_patient_file_system_list?' + query );
  },
  get_all_wdm_list () {
    return Vue.http.get(API_BASE_URL + 'project/get_all_wdm_list/');
  },
  get_my_patients_for_project (data) {
    return Vue.http.post(API_BASE_URL + 'project/get_my_patients_for_project/', data);
  },
  save_project_patients (data) {
    return Vue.http.post(API_BASE_URL + 'project/save_project_patients/', data);
  },
  get_projects_by_username (data) {
    return Vue.http.post(API_BASE_URL + 'project/get_projects_by_username', data);
  },
  get_wdm_data_for_project (data) {
    return Vue.http.post(API_BASE_URL + 'project/get_wdm_data_for_project', data);
  },
  get_one_cb_document (data) {
    return Vue.http.post(API_BASE_URL + 'project/get_one_cb_document', data);
  },
  delete_project (uuid) {
    return Vue.http.delete(API_BASE_URL + 'project/delete_project/' + uuid);
  },
  project_get_analyze_reports (query) {
    return Vue.http.delete(API_BASE_URL + 'v1/project/get/analyze/reports?' + query);
  }
};
