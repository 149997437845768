<template>
  <div>
    <b-row style="font-size: 10px;">
      <b-col cols="5" style="padding-right: 3px;">
        <b-card no-body header-tag="header">
          <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
            Parametre Seçimi
          </b-card-header>
          <template v-if="column_list.length > 0">
            <b-row>
              <b-col cols="12">
                <b-form-input placeholder="Parametre Filtreleme Alanı" v-model="d_searchText"></b-form-input>
              </b-col>
            </b-row>
            <div :style="f_calculateColumnListStyle()">
              <template v-for="(col, col_ind) in column_list">
                <template v-if="f_controlInAnalyzeList(col_ind) && f_searchText(col.label)">
                  <b-row :style="d_selectedColumnInd === col_ind ? 'margin: 0px; padding: 5px; background-color: #acebd5; cursor: pointer; border-bottom: solid 1px #8f9291;' : 'margin: 0px; padding: 5px; cursor: pointer; border-bottom: solid 1px #8f9291;'">
                    <b-col cols="8" @click="f_selectColumn(col_ind)">
                      <template v-if="dataset.menu && dataset.menu.data.labels_wdm_location_format && dataset.menu.data.labels_wdm_location_format.status && col.type === 'wdm'">
                        <wdm-parameter-location :col="col" :loc="col.loc" :wdm_name="col.wdm_name" :show_wdm_name_upper="false" :col_ind="col_ind"></wdm-parameter-location>
                      </template>
                      <template v-else>
                        {{ col_ind + 1 }} - {{ col.label }}
                      </template>
                    </b-col>
                    <b-col cols="4">
                      {{ col.param_type }}
                      <img v-if="col.date_ability && col.date_ability === 'yes'" class="pull-right" src="@/icon/2301012.png" style="width: 20px;" />
                    </b-col>
                  </b-row>
                </template>
              </template>
            </div>
          </template>
        </b-card>
      </b-col>
      <b-col cols="2" style="text-align: center; margin: 0px; padding: 0px;">
        <template v-if="analyze_model && ['mean', 'median', 'frequency', 'sum'].indexOf(analyze_model.type) !== -1">
          <b-button v-if="d_selectedColumnInd !== ''" id="add-analyze" style="margin-top: 150px;" variant="secondary" @click="f_addAnalyzeList()">
            <img src="@/icon/2940638.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
          </b-button>
          <b-button v-if="d_selectedAnalyzeColumnInd !== ''" id="add-analyze" style="margin-top: 150px;" variant="secondary" @click="f_deleteFromAnalzeList()">
            <img src="@/icon/2940638_left.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
          </b-button>
        </template>
        <template v-if="analyze_model && ['cross_columns', 'compare_means', 'compare_sums'].indexOf(analyze_model.type) !== -1">
          <b-button v-if="d_selectedColumnInd !== ''" id="add-analyze" style="margin-top: 75px;" variant="secondary" @click="f_addParameterList('row')">
            <img src="@/icon/2940638.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
          </b-button>
          <b-button v-if="d_selected_row_parameter_index !== ''" id="add-analyze" style="margin-top: 75px;" variant="secondary" @click="f_deleteFromParameterList('row')">
            <img src="@/icon/2940638_left.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
          </b-button>
          <br>
          <b-button v-if="d_selectedColumnInd !== ''" id="add-analyze" style="margin-top: 120px;" variant="secondary" @click="f_addParameterList('column')">
            <img src="@/icon/2940638.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
          </b-button>
          <b-button v-if="d_selected_column_parameter_index !== ''" id="add-analyze" style="margin-top:120px;" variant="secondary" @click="f_deleteFromParameterList('column')">
            <img src="@/icon/2940638_left.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
          </b-button>
        </template>
      </b-col>
      <b-col cols="5" style="padding-left: 3px;">
        <!-- kolonların kendisini analiz ediyorsak burayı kullanıyoruz. row ve column oluşturmadan -->
        <template v-if="['mean', 'median', 'frequency', 'sum'].indexOf(analyze_model.type) !== -1">
          <b-card no-body header-tag="header">
            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
              Seçili Parametreler
            </b-card-header>
            <template v-if="analyze_column_list.length > 0">
              <div :style="f_calculateColumnListStyle2()">
                <template v-for="(col, col_ind) in analyze_column_list">
                  <b-row :style="d_selectedAnalyzeColumnInd === col_ind ? 'margin: 0px; padding: 5px; background-color: #acebd5; cursor: pointer; border-bottom: solid 1px #8f9291;' : 'margin: 0px; padding: 5px; cursor: pointer; border-bottom: solid 1px #8f9291;'">
                    <b-col sm="12" md="6" @click="f_selectAnalyzeColumn(col_ind)">
                      <template v-if="dataset.menu && dataset.menu.data.labels_wdm_location_format && dataset.menu.data.labels_wdm_location_format.status && col.type === 'wdm'">
                        <wdm-parameter-location :col="col" :loc="col.loc" :wdm_name="col.wdm_name" :show_wdm_name_upper="false" :col_ind="col_ind"></wdm-parameter-location>
                      </template>
                      <template v-else>
                        {{ col_ind + 1 }} - {{ col.label }}
                      </template>
                    </b-col>
                    <b-col sm="12" md="6">
                      <template v-if="['select', 'object_list_options', 'radiogroup', 'checkbox'].indexOf(col.param_type) !== -1">
                        <select v-model="col.option_group_type" style="padding: 3px;" :disabled="col.type === 'manuel'">
                          <option value="1">Standart mod</option>
                          <option value="2">Her bir seçenek ayrı ayrı</option>
                          <option value="3">Detaylı kombinasyon</option>
                        </select>
                      </template>
                    </b-col>
                  </b-row>
                </template>
              </div>
            </template>
          </b-card>
        </template>
        <!-- row ve column oluşturarak karşılıklı analiz yapıyorsak burayı kullanıyoruz. -->
        <template v-if="['cross_columns', 'compare_means', 'compare_sums'].indexOf(analyze_model.type) !== -1">
          <b-card no-body header-tag="header" style="height: 150px;">
            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
              <b-row style="margin: 0px;">
                <b-col cols="6">
                  Satır Parametreleri (row)
                </b-col>
                <b-col cols="6">
                  <template v-if="row_parameter_list.length > 0">
                    <select v-model="analyze_model.row_parameter_group_type" style="padding: 3px; width: 100%; background: transparent; color: green;">
                      <option value="1">Her parametre ayrı ayrı</option>
                      <option value="2">Parametrelerden kombinasyonlar üret</option>
                    </select>
                  </template>
                </b-col>
              </b-row>
            </b-card-header>
            <template v-if="row_parameter_list.length > 0">
              <div style="overflow-x: hidden; overflow-y: auto; height: 300px;">
                <template v-for="(col, col_ind) in row_parameter_list">
                  <b-row style="margin: 0px;">
                    <b-col sm="12" md="6" @click="f_selectRowParameter(col_ind)" :style="d_selected_row_parameter_index === col_ind ? 'background-color: #acebd5; cursor: pointer; border-bottom: solid 1px #8f9291;' : 'cursor: pointer; border-bottom: solid 1px #8f9291;'">
                      <template v-if="dataset.menu && dataset.menu.data.labels_wdm_location_format && dataset.menu.data.labels_wdm_location_format.status && col.type === 'wdm'">
                        <wdm-parameter-location :col="col" :loc="col.loc" :wdm_name="col.wdm_name" :show_wdm_name_upper="false" :col_ind="col_ind"></wdm-parameter-location>
                      </template>
                      <template v-else>
                        {{ col_ind + 1 }} - {{ col.label }}
                      </template>
                    </b-col>
                    <b-col sm="12" md="6">
                      <template v-if="['select', 'object_list_options', 'radiogroup', 'checkbox'].indexOf(col.param_type) !== -1">
                        <select v-model="col.option_group_type" style="padding: 3px;" :disabled="col.type === 'manuel'">
                          <option value="1">Standart mod</option>
                          <option value="2">Her bir seçenek ayrı ayrı</option>
                          <option value="3">Detaylı kombinasyon</option>
                        </select>
                      </template>
                    </b-col>
                  </b-row>
                </template>
              </div>
            </template>
          </b-card>
          <b-card no-body header-tag="header" style="height: 150px;">
            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
              Kolon Parametreleri (column)
            </b-card-header>
            <template v-if="column_parameter_list.length > 0">
              <div style="overflow-x: hidden; overflow-y: auto; height: 300px;">
                <template v-for="(col, col_ind) in column_parameter_list">
                  <b-row style="margin: 0px;">
                    <b-col sm="12" md="6" @click="f_selectColumnParameter(col_ind)" :style="d_selected_column_parameter_index === col_ind ? 'background-color: #acebd5; cursor: pointer; border-bottom: solid 1px #8f9291;' : 'cursor: pointer; border-bottom: solid 1px #8f9291;'">
                      <template v-if="dataset.menu && dataset.menu.data.labels_wdm_location_format && dataset.menu.data.labels_wdm_location_format.status && col.type === 'wdm'">
                        <wdm-parameter-location :col="col" :loc="col.loc" :wdm_name="col.wdm_name" :show_wdm_name_upper="false" :col_ind="col_ind"></wdm-parameter-location>
                      </template>
                      <template v-else>
                        {{ col_ind + 1 }} - {{ col.label }}
                      </template>
                    </b-col>
                    <b-col sm="12" md="6">
                      <template v-if="['select', 'object_list_options', 'radiogroup', 'checkbox'].indexOf(col.param_type) !== -1">
                        <select v-model="col.option_group_type" style="padding: 3px;" :disabled="col.type === 'manuel'">
                          <option value="1">Standart mod</option>
                          <option value="2">Her bir seçenek ayrı ayrı</option>
                          <option value="3">Detaylı kombinasyon</option>
                        </select>
                      </template>
                    </b-col>
                  </b-row>
                </template>
              </div>
            </template>
          </b-card>
          <b-card no-body header-tag="header" style="height: 150px;">
            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
              İstatistik Ayarları
            </b-card-header>
            <div style="overflow-x: hidden; overflow-y: auto; height: 300px;">
              
            </div>
          </b-card>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  default as WdmParameterLocation
} from '@/components/widgets/WdmParameterLocation';
import { mapGetters } from 'vuex';
export default {
  name: 'WisdomStatsSelect',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    WdmParameterLocation
  },
  created: function () {},
  props: {
    column_list: {
      type: Array,
      required: true
    },
    analyze_column_list: {
      type: Array,
      required: true
    },
    row_parameter_list: {
      type: Array,
      required: true
    },
    column_parameter_list: {
      type: Array,
      required: true
    },
    analyze_model: {
      type: Object,
      required: true
    },
    dataset: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      d_selectedColumnInd: '',
      d_selectedAnalyzeColumnInd: '',
      d_selected_row_parameter_index: '',
      d_selected_column_parameter_index: '',
      d_searchText: ''
    };
  },
  methods: {
    f_searchText: function (label) {
      if (this.d_searchText) {
        if (label.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    f_calculateColumnListStyle2: function () {
      let style = '';
      let x = 210;
      style = 'border: solid 1px #adffdc; border-radius: 5px; overflow-x: hidden; overflow-y: auto; height: ' + (window.innerHeight - x).toString() + 'px';
      return style;
    },
    f_calculateColumnListStyle: function () {
      let style = '';
      let x = 240;
      style = 'border: solid 1px #adffdc; border-radius: 5px; padding: 5px; overflow-x: hidden; overflow-y: auto; height: ' + (window.innerHeight - x).toString() + 'px';
      return style;
    },
    f_deleteFromParameterList: function (type) {
      if (type === 'row') {
        if (this.d_selected_row_parameter_index !== '') {
          this.row_parameter_list.splice(this.d_selected_row_parameter_index, 1);
          this.d_selected_row_parameter_index = '';
        } else {
          alert('Seçili parametreniz bulunmamaktadır.');
        }
      } else if (type === 'column') {
        if (this.d_selected_column_parameter_index !== '') {
          this.column_parameter_list.splice(this.d_selected_column_parameter_index, 1);
          this.d_selected_column_parameter_index = '';
        } else {
          alert('Seçili parametreniz bulunmamaktadır.');
        }
      }
      this.$forceUpdate();
    },
    f_addParameterList: function (type) {
      let data = {};
      data.label = this.column_list[this.d_selectedColumnInd].label;
      data.option_group_type = '1';
      data.col_index = this.d_selectedColumnInd;
      data.param_type = this.column_list[this.d_selectedColumnInd].param_type;
      data.type = this.column_list[this.d_selectedColumnInd].type;
      if (this.column_list[this.d_selectedColumnInd].loc) {
        data.loc = this.column_list[this.d_selectedColumnInd].loc;
      }
      if (this.column_list[this.d_selectedColumnInd].wdm_name) {
        data.wdm_name = this.column_list[this.d_selectedColumnInd].wdm_name;
      }
      if (this.d_selectedColumnInd !== '') {
        if (type === 'row') {
          this.row_parameter_list.push(data);
        } else if (type === 'column') {
          this.column_parameter_list.push(data);
        }
        this.d_selectedColumnInd = '';
      } else {
        alert('Lütfen parametre seçimi yapınız');
      }
      this.$forceUpdate();
    },
    f_controlInAnalyzeList: function (col_ind) {
      for (let i in this.analyze_column_list) {
        if (col_ind === this.analyze_column_list[i].col_index) {
          return false;
        }
      }
      return true;
      /*
        if (this.analyze_column_list[i].type === 'wdm' && col.loc) {
          if (col.loc.param.value === this.analyze_column_list[i].loc.param.value) {
            return false;
          }
        } else {
          if (col.value === this.analyze_column_list[i].value) {
            return false;
          }
        }
        */
    },
    f_addAnalyzeList: function () {
      if (this.d_selectedColumnInd !== '') {
        let data = {};
        data.label = this.column_list[this.d_selectedColumnInd].label;
        data.option_group_type = '1';
        data.col_index = this.d_selectedColumnInd;
        data.param_type = this.column_list[this.d_selectedColumnInd].param_type;
        data.type = this.column_list[this.d_selectedColumnInd].type;
        if (this.column_list[this.d_selectedColumnInd].loc) {
          data.loc = this.column_list[this.d_selectedColumnInd].loc;
        }
        if (this.column_list[this.d_selectedColumnInd].wdm_name) {
          data.wdm_name = this.column_list[this.d_selectedColumnInd].wdm_name;
        }
        this.analyze_column_list.push(data);
        this.d_selectedColumnInd = '';
      } else {
        alert('Lütfen parametre seçimi yapınız');
      }
      this.$forceUpdate();
    },
    f_selectColumn: function (col_ind) {
      this.d_selectedAnalyzeColumnInd = '';
      this.d_selected_column_parameter_index = '';
      this.d_selected_row_parameter_index = '';
      this.d_selectedColumnInd = col_ind;
      this.$forceUpdate();
    },
    f_selectColumnParameter: function (col_ind) {
      this.d_selectedColumnInd = '';
      this.d_selected_column_parameter_index = col_ind;
      this.$forceUpdate();
    },
    f_selectRowParameter: function (col_ind) {
      this.d_selectedColumnInd = '';
      this.d_selected_row_parameter_index = col_ind;
      this.$forceUpdate();
    },
    f_selectAnalyzeColumn: function (col_ind) {
      this.d_selectedColumnInd = '';
      this.d_selectedAnalyzeColumnInd = col_ind;
      this.$forceUpdate();
    },
    f_deleteFromAnalzeList: function () {
      if (this.d_selectedAnalyzeColumnInd !== '') {
        this.analyze_column_list.splice(this.d_selectedAnalyzeColumnInd, 1);
        this.d_selectedAnalyzeColumnInd = '';
      } else {
        alert('Seçili parametreniz bulunmamaktadır.');
      }
    }
  }
};

</script>

<style type="text/css">


</style>

