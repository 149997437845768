<template>
  <div>
    <table>
      <thead>
        <tr>
          <th :style="'position: sticky; z-index: 1000; top: 0; text-align: center; background-color: ' + p_polestarSettings.style.backgroundColor">
            <img src="@/icon/2653039.png" class="img-rounded img-responsive" style="width: 3em;" />
          </th>
          <!-- Row WisdomEra ID and Client ID details -->
          <template v-if="d_dataset.menu && d_dataset.menu.data.row_id_details && d_dataset.menu.data.row_id_details.status">
            <th :style="'position: sticky; z-index: 1000; top: 0; white-space: nowrap; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: ' + p_polestarSettings.style.backgroundColor + '; min-width: 20px;'">
              <div style="font-size: 10px;">
                <img src="@/icon/2991711.png" style="width: 1.5em;" /> WisdomEra ID
              </div>
              <b-row>
                <b-col cols="12">
                  <b-button variant="secondary" style="width: 100%; height: 10px; padding: 0px; margin: 0px 3px 0px 0px;"></b-button>
                </b-col>
              </b-row>
            </th>
            <th :style="'position: sticky; z-index: 1000; top: 0; white-space: nowrap; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: ' + p_polestarSettings.style.backgroundColor + '; min-width: 20px;'">
              <div style="font-size: 10px;">
                <img src="@/icon/2991711.png" style="width: 1.5em;" /> Client ID
              </div>
              <b-row>
                <b-col cols="12">
                  <b-button variant="secondary" style="width: 100%; height: 10px; padding: 0px; margin: 0px 3px 0px 0px;"></b-button>
                </b-col>
              </b-row>
            </th>
            <th :style="'position: sticky; z-index: 1000; top: 0; white-space: nowrap; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: ' + p_polestarSettings.style.backgroundColor + '; min-width: 20px;'">
              <div style="font-size: 10px;">
                <img src="@/icon/2991711.png" style="width: 1.5em;" /> Root Key
              </div>
              <b-row>
                <b-col cols="12">
                  <b-button variant="secondary" style="width: 100%; height: 10px; padding: 0px; margin: 0px 3px 0px 0px;"></b-button>
                </b-col>
              </b-row>
            </th>
          </template>
          <template v-if="d_rowDmpAnalyzeResults.columns.length > 0">
            <th v-for="(dmp_col, dmp_col_ind) in d_rowDmpAnalyzeResults.columns" style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: #ececec; width: 200px;">
              <div :title="dmp_col">{{ dmp_col.substring(0, 20) }} ...</div>
              <b-row>
                <b-col cols="12">
                  <b-button variant="warning" style="width: 100%; height: 10px; padding: 0px; margin: 0px 3px 0px 0px;"></b-button>
                </b-col>
              </b-row>
            </th>
          </template>
          <!-- TH dmp_sms_operation -->
          <template v-for="(col, col_ind) in column_list">
            <template v-if="col.rule_id !== undefined">
              <th :style="'position: sticky; z-index: 1000; top: 0; white-space: nowrap; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: ' + p_polestarSettings.style.backgroundColor + '; width: 200px;'">
                <div style="font-size: 10px;" :title="col.label">
                  <img src="@/icon/1430869.png" style="width: 1.5em;" /> {{ col.rule_id }} - {{ col.label.length > 20 ? col.label.substring(0, 20) + '...' : col.label }}
                </div>
                <b-row>
                  <b-col cols="12">
                    <b-button :style="'width: 100%; height: 10px; padding: 0px; margin: 0px 3px 0px 0px; background: #1dddf0; border-color: #1dddf0;'"></b-button>
                  </b-col>
                </b-row>
              </th>
            </template>
          </template>
          <template v-for="(col, col_ind) in column_list">
            <!-- Main TH -->
            <template v-if="col.rule_id === undefined">
              <th :key="'th_' + col_ind.toString()" :title="col.label + ' < ' + col.type + '>' + ' < ' + col.param_type + '>'" v-if="f_filterColumn(col, col_ind)" :style="'position: sticky; z-index: 1000; top: 0; white-space: nowrap; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: ' + p_polestarSettings.style.backgroundColor + '; min-width: 20px;'">
                <!--                     <template v-if="d_dataset.main_column_val_list && d_dataset.main_column_val_list.length > 0 && d_dataset.main_column_val_list[col_ind] && d_dataset.main_column_val_list[col_ind].length !== d_dataset.column_val_list[col_ind].length">
                          <img src="@/icon/1517831.png" style="width: 20px;" />
                        </template>
     -->
                <div @click="f_selectCell('', col_ind,'col')" style="font-size: 10px;">
                  <img v-if="col.sms_phone" src="@/icon/1013986.png" style="width: 1.5em;" title="Sms telefonu olarak kullanılacak olan kolon" />
                  <small>{{ col_ind + 1 }} -</small> {{ col.label.length > 10 ? col.label.substring(0, 10) + '...' : col.label }}
                  <template v-if="col.date_ability && col.date_ability ==='yes'">
                    <img src="@/icon/2301012.png" style="width: 12px;" />
                  </template>
                </div>
                <b-row>
                  <b-col cols="12">
                    <b-button variant="success" @click="f_showColumnModal(col_ind)" style="width: 100%; height: 10px; padding: 0px; margin: 0px 3px 0px 0px;"></b-button>
                  </b-col>
                </b-row>
              </th>
            </template>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="(row, row_ind) in d_dataset.case_rows">
          <tr style="border-bottom: solid 1px #c1c7cc;">
            <td :id="'td_row_no_' + row_ind.toString()" :style="f_calculateRowNoStyle(row_ind)" @click="f_selectCell(row_ind,'','row')" @contextmenu.prevent="f_showRightClickMenu" :title="f_calculateRowTitle(row_ind)">
              <!-- <b-button variant="success" @click="f_showRowModal(row_ind)" style="width: 15px; height: 15px; padding: 0px; margin: 0px 3px 0px 0px;"></b-button> -->
              {{ d_dataset.case_id_list[row_ind].no }}
            </td>
            <template v-if="d_rowDmpAnalyzeResults.columns.length > 0">
              <td v-for="(dmp_col, dmp_col_ind) in d_rowDmpAnalyzeResults.columns" style="white-space: nowrap; border-right: solid 1px #c1c7cc; background-color: #fff9d4; width: 150px;">
                <span v-if="f_thisRuleHasTrueResult(dmp_col, row_ind)">evet</span>
              </td>
            </template>
            <!-- Row WisdomEra ID and Client ID details -->
            <template v-if="d_dataset.menu && d_dataset.menu.data.row_id_details && d_dataset.menu.data.row_id_details.status">
              <template v-if="d_dataset.case_id_list[row_ind].id">
                <td style="white-space: nowrap; border-right: solid 1px #c1c7cc; background-color: #fff9d4; width: 150px;">
                  {{ d_dataset.case_id_list[row_ind].id }}
                </td>
              </template>
              <template v-else>
                <td style="white-space: nowrap; border-right: solid 1px #c1c7cc; background-color: #fff9d4; width: 150px;">
                  ---
                </td>
              </template>
              <template v-if="d_dataset.case_id_list[row_ind].client_id">
                <td style="white-space: nowrap; border-right: solid 1px #c1c7cc; background-color: #fff9d4; width: 150px;">
                  {{ d_dataset.case_id_list[row_ind].client_id }}
                </td>
              </template>
              <template v-else>
                <td style="white-space: nowrap; border-right: solid 1px #c1c7cc; background-color: #fff9d4; width: 150px;">
                  ---
                </td>
              </template>
              <template v-if="d_dataset.case_id_list[row_ind].id_tree && d_dataset.case_id_list[row_ind].id_tree.length > 0">
                <td style="white-space: nowrap; border-right: solid 1px #c1c7cc; background-color: #fff9d4; width: 150px;">
                  {{ d_dataset.case_id_list[row_ind].id_tree[0].id.join('_') }}
                </td>
              </template>
              <template v-else>
                <td style="white-space: nowrap; border-right: solid 1px #c1c7cc; background-color: #fff9d4; width: 150px;">
                  ---
                </td>
              </template>
            </template>
            <!-- SMS columns -->
            <template v-for="(col, col_ind) in column_list">
              <template v-if="col.rule_id !== undefined">
                <td :style="'white-space: nowrap; border-right: solid 1px #c1c7cc; background-color: white; min-width: 10px;'">
                  <template v-if="d_dataset.menu && d_dataset.menu.data.show_date_mode && d_dataset.menu.data.show_date_mode.status">
                    <div id="table_cell_info" style="overflow-y: auto; max-height: 150px;">
                      <template v-for="(wdmr, wdmr_key, wdmr_index) in d_dataset.cell_dates[row_ind][col_ind]">
                        <div :key="'wdmr_' + col_ind.toString() + '_' + wdmr_key" :style="wdmr_index % 2 === 0 ? 'cursor: pointer; background: #f3f6f6' : 'cursor: pointer;'">
                          <div><strong>id:</strong> {{ wdmr_key }}</div>
                          <template v-if="wdmr.id_tree && wdmr.id_tree.length > 0">
                            <div v-for="(id_tree, id_tree_ind) in wdmr.id_tree">
                              <template v-if="id_tree.id && id_tree.id.length > 0">
                                <strong>root:</strong> {{ id_tree.id.join('_') }}
                              </template>
                            </div>
                          </template>
                          <template v-if="wdmr.date">
                            <strong>date:</strong> {{ DateFormatTime(wdmr.date) }}
                          </template>
                          <template v-if="wdmr.created_by">
                            <strong>created by:</strong> {{ wdmr.created_by }}
                          </template>
                          <template v-if="wdmr.api_status_message">
                            <strong>api status message</strong> {{ wdmr.api_status_message }}
                          </template>
                          <div><strong>values:</strong></div>
                          <template v-if="wdmr.list" v-for="(rec, rec_ind) in wdmr.list">
                            <div :key="'wdmr_' + col_ind.toString() + '_' + wdmr_key + '_' + rec_ind.toString()">
                              *
                              <span v-if="rec.val.label" @click="f_showTextModal(rec.val.label)" style="cursor: pointer;">
                                {{ rec.val.label.length > 50 ? rec.val.label.substring(0, 50) + '...' : rec.val.label }}
                              </span>
                              <span v-else @click="f_showTextModal(rec.val)" style="cursor: pointer;">
                                {{ rec.val.length > 50 ? rec.val.substring(0, 50) + '...' : rec.val }}
                              </span>
                            </div>
                          </template>
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-else>
                    <template v-if="d_editCell === row_ind.toString() + '_' + col_ind.toString()">
                    </template>
                    <template v-else>
                      <div :id="'td_cell_div_' + row_ind.toString() + '_' + col_ind.toString()" style="width: 100%; min-height: 10px;" :title="row[col_ind]">
                        <template v-if="row[col_ind] !== ''">
                          {{ f_showThisChars(row[col_ind], 20) }}
                          <!-- {{ row[col_ind] }} -->
                        </template>
                      </div>
                    </template>
                  </template>
                </td>
              </template>
            </template>
            <template v-for="(col, col_ind) in column_list">
              <template v-if="col.rule_id === undefined">
                <td :id="'td_cell_' + row_ind.toString() + '_' + col_ind.toString()" v-if="f_filterColumn(col, col_ind)" :style="f_calculateStyleDatasetTable(row_ind, col_ind)" @click="f_selectCell(row_ind, col_ind,'cell')" class="cell-class" :title="w_presentation ? 'Sunum modu açık' : row[col_ind]">
                  <!-- <template v-if="d_dataset.menu && d_dataset.menu.data.edit_cells_mode && d_dataset.menu.data.edit_cells_mode.status"> -->
                  <template v-if="w_presentation && (col.sms_phone || col.person_id || col.person_firstname || col.person_lastname || col.name || col.anonym)">
                    <!-- {{ col.label }} -->
                    <div style="width: 100%; text-align: center;">
                      <img src="@/icon/1748850.png" style="width: 1em;" />
                    </div>
                  </template>
                  <template v-else>
                    <template v-if="d_dataset.menu && d_dataset.menu.data.show_date_mode && d_dataset.menu.data.show_date_mode.status">
                      <div id="table_cell_info" style="overflow-y: auto; max-height: 150px;">
                        <template v-for="(wdmr, wdmr_key, wdmr_index) in d_dataset.cell_dates[row_ind][col_ind]">
                          <div :key="'wdmr_' + col_ind.toString() + '_' + wdmr_key" :style="wdmr_index % 2 === 0 ? 'background: #f3f6f6' : ''">
                            <div><strong>id:</strong> {{ wdmr_key }}</div>
                            <template v-if="wdmr.id_tree && wdmr.id_tree.length > 0">
                              <div v-for="(id_tree, id_tree_ind) in wdmr.id_tree">
                                <template v-if="id_tree.id && id_tree.id.length > 0">
                                  <strong>root:</strong> {{ id_tree.id.join('_') }}
                                </template>
                              </div>
                            </template>
                            <template v-if="wdmr.date">
                              <strong>date:</strong> {{ DateFormatTime(wdmr.date) }}
                            </template>
                            <template v-if="wdmr.created_by">
                              <strong>created by:</strong> {{ wdmr.created_by }}
                            </template>
                            <div><strong>values:</strong></div>
                            <template v-if="wdmr.list" v-for="(rec, rec_ind) in wdmr.list">
                              <div :key="'wdmr_' + col_ind.toString() + '_' + wdmr_key + '_' + rec_ind.toString()">
                                * {{ rec.anatomy ? rec.anatomy.label +'->' :'' }}
                                <span v-if="rec.val.label" @click="f_showTextModal(rec.val.label)" style="cursor: pointer;">
                                  {{ rec.val.label.length > 50 ? rec.val.label.substring(0, 50) + '...' : rec.val.label }}
                                </span>
                                <span v-else @click="f_showTextModal(rec.val)" style="cursor: pointer;">
                                  {{ rec.val.length > 50 ? rec.val.substring(0, 50) + '...' : rec.val }}
                                </span>
                              </div>
                            </template>
                          </div>
                          <!-- <div v-if="wdmr_index !== Object.keys(d_dataset.cell_dates[row_ind][col_ind]).length - 1" style="border-bottom: solid 1px blue"></div> -->
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      <template v-if="d_editCell === row_ind.toString() + '_' + col_ind.toString()">
                        <template v-if="col.param_type ==='text'">
                          <input type="text" :id="'cell_' + row_ind.toString() + '_' + col_ind.toString()" v-model="d_cellTextTimer.text" :style="d_selected_cells.indexOf((d_datasetPagination.start + row_ind).toString() +'-' + col_ind.toString()) !== -1 ?'padding: 2px; width: 100%; border: none; background-color: #cffbcf;' :'padding: 2px; width: 100%; border: none;'">
                        </template>
                        <template v-if="['date','datetime'].indexOf(col.param_type) !== -1">
                          <input type="date" :id="'cell_' + row_ind.toString() + '_' + col_ind.toString()" v-model="d_cellTextTimer.text" :style="d_selected_cells.indexOf((d_datasetPagination.start + row_ind).toString() +'-' + col_ind.toString()) !== -1 ?'padding: 2px; width: 100%; border: none; background-color: #cffbcf;' :'padding: 2px; width: 100%; border: none;'">
                        </template>
                        <template v-if="col.param_type ==='textarea'">
                          <input type="text" :id="'cell_' + row_ind.toString() + '_' + col_ind.toString()" v-model="d_cellTextTimer.text" :style="d_selected_cells.indexOf((d_datasetPagination.start + row_ind).toString() +'-' + col_ind.toString()) !== -1 ?'padding: 2px; width: 100%; border: none; background-color: #cffbcf;' :'padding: 2px; width: 100%; border: none;'">
                        </template>
                        <template v-if="col.param_type ==='integer'">
                          <input type="number" :id="'cell_' + row_ind.toString() + '_' + col_ind.toString()" v-model="d_cellTextTimer.text" :style="d_selected_cells.indexOf((d_datasetPagination.start + row_ind).toString() +'-' + col_ind.toString()) !== -1 ?'padding: 2px; width: 100%; border: none; background-color: #cffbcf;' :'padding: 2px; width: 100%; border: none;'">
                        </template>
                        <template v-if="col.param_type ==='float'">
                          <input type="number" :id="'cell_' + row_ind.toString() + '_' + col_ind.toString()" v-model="d_cellTextTimer.text" :style="d_selected_cells.indexOf((d_datasetPagination.start + row_ind).toString() +'-' + col_ind.toString()) !== -1 ?'padding: 2px; width: 100%; border: none; background-color: #cffbcf;' :'padding: 2px; width: 100%; border: none;'">
                        </template>
                        <template v-if="['select','radiogroup','object_list_options','checkbox'].indexOf(col.param_type) !== -1">
                          <select :id="'cell_' + row_ind.toString() + '_' + col_ind.toString()" v-model="d_cellTextTimer.text" :style="d_selected_cells.indexOf((d_datasetPagination.start + row_ind).toString() +'-' + col_ind.toString()) !== -1 ?'padding: 2px; width: 100%; border: none; background-color: #cffbcf;' :'padding: 2px; width: 100%; border: none;'">
                            <template v-if="col.options">
                              <option value=""></option>
                              <option v-for="(opt, opt_ind) in col.options" :value="opt.value">{{ opt.label }}</option>
                            </template>
                          </select>
                        </template>
                      </template>
                      <template v-else>
                        <div :id="'td_cell_div_' + row_ind.toString() + '_' + col_ind.toString()" @dblclick="f_editThisCell(row_ind, col_ind)" style="width: 100%; min-height: 10px;" @contextmenu.prevent="f_showRightClickMenu">
                          <template v-if="row[col_ind] !== ''">
                            {{ f_showThisChars(row[col_ind], 15) }}
                          </template>
                        </div>
                      </template>
                    </template>
                  </template>
                </td>
              </template>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
    <b-modal v-if="d_columnData.show" id="d_columnData_show" v-model="d_columnData.show" :title="column_list[d_columnData.col_index].label" centered no-close-on-esc centered no-close-on-backdrop hide-header-close header-bg-variant="info" header-text-variant="dark" size="xl">
      <pole-star-column-feature :column_list="column_list" :option_data="option_data" :p_selectedProjectData="p_selectedProjectData" :d_tableModeSettings="d_tableModeSettings" :column_index="d_columnData.col_index" :mother_children="mother_children" :p_polestarSettings="p_polestarSettings" :p_userPolestarPermissions="p_userPolestarPermissions" :f_controlAndCreateTimer="f_controlAndCreateTimer"></pole-star-column-feature>
      <template slot="modal-footer">
        <b-button variant="danger" @click="f_cancelColumnEdit()">{{ $t('wdm16.4053') }}</b-button>
        <b-button variant="success" @click="f_motherChildren('save_columns'); d_columnData.show = false">{{ $t('wdm16.4061') }}</b-button>
      </template>
    </b-modal>
    <b-modal v-if="d_showTextModal.show" id="d_showTextModal_show" v-model="d_showTextModal.show" scrollable hide-footer title="Hücre Tam Görünümü" centered header-bg-variant="info" header-text-variant="dark">
      <p class="my-4" style="word-wrap: break-word;">
        {{ d_showTextModal.text }}
      </p>
    </b-modal>
  </div>
</template>

<script type="text/javascript">
import {
  default as PoleStarColumnFeature
} from '@/components/widgets/PoleStarColumnFeature';

import SmsService from '@/services/sms';
import RuleEngineService from '@/services/rule_engine';
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import {
  default as Modal
} from "@/components/widgets/Modal";
import FileSaver from 'file-saver';
import { ClsWdmrForever, ClsWdmrSave } from "@/services/public/wdm";
import PoleStarService from '@/services/polestar';
import FiletransferService from '@/services/file_transfer';
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'WisdomStatsDataTable',
  computed: mapGetters({
    lang: 'lang',
    device: 'device'
  }),
  props: {
    p_polestarSettings: {
      type: Object,
      required: true
    },
    d_editCell: {
      type: String,
      required: true
    },
    d_datasetPagination: {
      type: Object,
      required: true
    },
    d_searchColumnTextTimer: {
      type: Object,
      required: true
    },
    d_rowDmpAnalyzeResults: {
      type: Object,
      required: true
    },
    p_f_fastAnalyzeMode: {
      type: Function,
      required: true
    },
    f_thisRuleHasTrueResult: {
      type: Function,
      required: true
    },
    f_controlAndCreateTimer: {
      type: Function,
      required: true
    },
    f_getAndShowThisWdmr: {
      type: Function,
      required: true
    },
    f_showRightClickMenu: {
      type: Function,
      required: true
    },
    f_editThisCell: {
      type: Function,
      required: true
    },
    f_calculateStyleDatasetTable: {
      type: Function,
      required: true
    },
    f_calculateRowNoStyle: {
      type: Function,
      required: true
    },
    f_selectCell: {
      type: Function,
      required: true
    },
    d_dataset: {
      type: Object,
      required: true
    },
    p_selectedProjectData: {
      type: Object,
      required: true
    },
    option_data: {
      type: Object,
      required: true
    },
    d_cellTextTimer: {
      type: Object,
      required: true
    },
    mother_children: {
      type: Object,
      required: true
    },
    d_tableModeSettings: {
      type: Object,
      required: true
    },
    column_list: {
      type: Array,
      required: true
    },
    d_selected_cells: {
      type: Array,
      required: true
    },
    p_userPolestarPermissions: {
      type: Array,
      required: true
    }
  },
  components: {
    Modal,
    WisdomDataModal,
    PoleStarColumnFeature
  },
  data () {
    return {
      w_presentation: false,
      d_searchColumnText: '',
      d_columnData: { 'show': false, 'col_index': '' },
      d_showTextModal: { 'show': false, 'text': '' },
      d_columnBackupData: {}
    };
  },
  created: function () {
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {},
  methods: {
    f_showTextModal: function (txt) {
      this.d_showTextModal.text = txt;
      this.d_showTextModal.show = true;
    },
    f_calculateRowTitle: function (row_ind) {
      let title = '';
      if (this.d_dataset.case_id_list[row_ind].id) {
        title = 'Wisdomera ID = ' + this.d_dataset.case_id_list[row_ind].id
      }
      if (this.d_dataset.case_id_list[row_ind].client_id) {
        title += ' & Dış uygulama ID = ' + this.d_dataset.case_id_list[row_ind].client_id;
      }
      if (this.d_dataset.case_id_list[row_ind].id_tree && this.d_dataset.case_id_list[row_ind].id_tree.length > 0) {
        title += ' & Root Key = ' + this.d_dataset.case_id_list[row_ind].id_tree[0]['id'].join('_');
      }
      return title;
    },
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
    },
    DateFormatTime: function (date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
    },
    f_motherChildren: function (op) {
      if (op === 'save_columns') {
        this.d_columnBackupData = JSON.parse(JSON.stringify(this.column_list[this.d_columnData.col_index]));
      }
      if (this.mother_children[op]) {
        this.mother_children[op] = 0;
      } else {
        this.mother_children[op] = 1;
      }
    },
    f_cancelColumnEdit: function () {
      this.column_list[this.d_columnData.col_index] = JSON.parse(JSON.stringify(this.d_columnBackupData));
      setTimeout(function () {
        this.d_columnData = {
          'show': false,
          'col_index': ''
        };
        this.$forceUpdate();
      }.bind(this), 50);
    },
    f_showColumnModal: function (col_index) {
      // console.log(this.column_list[col_index]);
      this.d_columnBackupData = JSON.parse(JSON.stringify(this.column_list[col_index]));
      this.d_columnData = {
        'show': true,
        'col_index': col_index
      };
      this.$forceUpdate();
    },
    f_filterColumn: function (col, col_ind) {
      if (this.d_tableModeSettings.show_selected_columns.length === 0) {
        if (this.d_searchColumnText) {
          let text_list = this.d_searchColumnText.toLocaleLowerCase().split(',');
          for (let i in text_list) {
            text_list[i] = text_list[i].replace(/ /g, "");
          }
          for (let t in text_list) {
            if (text_list[t]) {
              if (col.label.toLocaleLowerCase().indexOf(text_list[t]) !== -1) {
                return true;
              }
            }
          }
        } else {
          if (col_ind >= this.d_tableModeSettings.start && col_ind <= this.d_tableModeSettings.end) {
            return true;
          }
        }
      } else {
        if (this.d_tableModeSettings.show_selected_columns.indexOf(col_ind) !== -1) {
          return true;
        }
      }
      return false;
    },
    f_showThisChars: function (text, count) {
      let str = '';
      if (text !== '' && text !== undefined) {
        text = text.toString();
        let x = text.length > count ? count : text.length;
        for (let i = 0; i < x; i++) {
          if (text[i]) {
            str += text[i];
          } else {
            str += '.';
          }
        }
        if (text.length > count) {
          str += '...';
        }
      }
      return str;
    }
  },
  watch: {
    'mother_children.forceUpdate': function () {
      this.$forceUpdate();
    },
    'd_searchColumnTextTimer.text': function () {
      clearTimeout(this.d_searchColumnTextTimer.timeout);
      let vm = this;
      this.d_searchColumnTextTimer.timeout = setTimeout(function () {
        vm.d_searchColumnText = vm.d_searchColumnTextTimer.text;
      }, 1000);
    }
  }
}

</script>

<style type="text/css">

#table_cell_info::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #d9e9ea;
}

#table_cell_info::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

#table_cell_info::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

</style>

