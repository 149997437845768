<template>
  <div>
    <b-card no-body header-tag="header" :class="[d_fullScreen ?'full-screen-mode' :'normal-mode']">
      <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
        <small>Makale Detayları</small>
        <b-button class="pull-right" size="md" :variant="d_fullScreen ?'warning' :'secondary'" :style="d_fullScreen ?'margin-right: 5px; padding: 0px; border: solid 2px green;' :'margin-right: 5px; padding: 0px;'" @click="d_fullScreen ? d_fullScreen = false : d_fullScreen = true">
          <img src="@/icon/629153.png" :title="$t('wdm16.6589')" style="width: 2em;" />
        </b-button>
        <b-button class="pull-right" size="md" :variant="d_editMode ?'warning' :'secondary'" :style="d_editMode ?'margin-right: 5px; padding: 0px; border: solid 2px green;' :'margin-right: 5px; padding: 0px;'" @click="d_editMode ? d_editMode = false : d_editMode = true">
          <img src="@/icon/2930471.png" title="Düzenleme Modu" style="width: 2em;" />
        </b-button>
        <b-button class="pull-right" size="sm" @click="f_saveArticle()" style="margin: 3px;">
          <img src="@/icon/1517831.png" style="width: 20px;" /> kaydet
        </b-button>
      </b-card-header>
      <b-row v-if="p_selectedWdm73Data.data.general && p_selectedWdm73Data.data.general.article_name && p_selectedWdm73Data.data.general.article_name.val" style="margin-left: 0px;">
        <b-col cols="12">
          <template>
            <span id="article_name" style="font-weight: bold;" :contentEditable="d_editMode">{{ p_selectedWdm73Data.data.general.article_name.val }}</span>
          </template>
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 5px; margin-top: 5px; margin-left: 0px;">
        <b-col cols="12">
          <span style="font-weight: bold;">Abstract</span>
        </b-col>
      </b-row>
      <template v-for="(part, part_ind) in d_partList.article_summary.param_order">
        <b-row style="margin-bottom: 5px; margin-left: 0px;">
          <b-col cols="12">
            <span style="font-weight: bold;">{{  d_partList.article_summary.parameters[part][d_selectedLang] }}: </span>
            <template v-if="p_selectedWdm73Data.data.article_summary['article_summary_' + part] && p_selectedWdm73Data.data.article_summary['article_summary_' + part].list && p_selectedWdm73Data.data.article_summary['article_summary_' + part].list.length > 0">
              <template v-for="(x, x_ind) in p_selectedWdm73Data.data.article_summary['article_summary_' + part].list">
                <template v-if="x['article_summary_' + part + '_info'] && x['article_summary_' + part + '_info'].val">
                  <div :id="'article_summary_' + part + '_' + x_ind + '_info'" :style="f_calculateStyleWriting('article_summary_' + part + '_' + x_ind + '_info')" :contentEditable="d_editMode">{{ x['article_summary_' + part + '_info'].val }} </div>
                </template>
              </template>
            </template>
          </b-col>
        </b-row>
      </template>
      <template v-for="(part, part_ind) in d_partList.article.param_order">
        <b-row style="margin-bottom: 5px; margin-top: 5px; margin-left: 0px;">
          <b-col cols="12">
            <span style="font-weight: bold;">{{  d_partList.article.parameters[part][d_selectedLang] }}</span>
          </b-col>
        </b-row>
        <template v-if="p_selectedWdm73Data.data.article['article_' + part] && p_selectedWdm73Data.data.article['article_' + part].list && p_selectedWdm73Data.data.article['article_' + part].list.length > 0">
          <b-row style="margin-bottom: 5px; margin-left: 0px;">
            <b-col cols="12">
              <template v-for="(x, x_ind) in p_selectedWdm73Data.data.article['article_' + part].list">
                <template v-if="x['article_' + part + '_info'] && x['article_' + part + '_info'].val">
                  <div :id="'article_' + part + '_' + x_ind + '_info'" :style="f_calculateStyleWriting('article_' + part + '_' + x_ind + '_info')" :contentEditable="d_editMode">{{ x['article_' + part + '_info'].val }} </div>
                </template>
              </template>
            </b-col>
          </b-row>
        </template>
      </template>
    </b-card>
  </div>
</template>

<script>
import PoleStarService from '@/services/polestar';
import { mapGetters } from 'vuex';
import {
  default as Modal
} from '@/components/widgets/Modal';

export default {
  name: 'PolestarFastAnalyze',
  components: {
    Modal
  },
  props: {
    p_selectedProjectData: {
      type: Object,
      required: true
    },
    p_selectedWdm73Data: {
      type: Object,
      required: true
    },
    mother_children: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      device: 'device'
    })
  },
  created: function () {
    // this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {},
  data () {
    return {
      d_editMode: false,
      d_fullScreen: false,
      d_selectedLang: 'en',
      d_partList: {
        'article_summary': {
          'param_order': ['purpose', 'methods', 'results', 'conclusion', 'key_words'],
          'parameters': {
            'purpose': {
              'tr': 'Amaç',
              'en': 'Purpose'
            },
            'methods': {
              'tr': 'Metodlar',
              'en': 'Methods'
            },
            'results': {
              'tr': 'Sonuçlar',
              'en': 'Results'
            },
            'conclusion': {
              'tr': 'Tartışma',
              'en': 'Conclusion'
            },
            'key_words': {
              'tr': 'Anahtar Kelimeler',
              'en': 'Key Words'
            }
          }
        },
        'article': {
          'param_order': ['general_information', 'methods', 'results', 'statistics', 'conclusion', 'conflict_of_interest'],
          'parameters': {
            'general_information': {
              'tr': 'Genel Bilgiler',
              'en': 'General Information'
            },
            'methods': {
              'tr': 'Metodlar',
              'en': 'Methods'
            },
            'results': {
              'tr': 'Sonuçlar',
              'en': 'Results'
            },
            'statistics': {
              'tr': 'İstatistikler',
              'en': 'Statistics'
            },
            'conclusion': {
              'tr': 'Tartışma',
              'en': 'Conclusion'
            },
            'conflict_of_interest': {
              'tr': 'Çıkar Çatışması',
              'en': 'Conflict of Interest'
            }
          }
        }
      },
    }
  },
  methods: {
    f_clickWritingByKeyboard: function (event) {
      console.log(event.which);
      if (event.which === 13) {}
    },
    f_calculateStyleWriting: function (doc_id) {
      let style = '';
      let doc = document.getElementById(doc_id);
      if (doc && doc.textContent) {
        style += 'display: inline; width: ' + (doc.textContent.length * 10) + 'px;';
      } else {
        style += 'display: inline; width: 30px;';
      }
      return style;
    },
    f_saveArticle: function () {
      this.p_selectedWdm73Data.data.general.article_name = { 'val': '' };
      this.p_selectedWdm73Data.label = '';
      let article_name_el = document.getElementById('article_name');
      if (article_name_el && article_name_el.textContent) {
        this.p_selectedWdm73Data.data.general.article_name.val = article_name_el.textContent;
        this.p_selectedWdm73Data.label = article_name_el.textContent;
      } else {
        article_name_el.innerHTML = '...';
        this.p_selectedWdm73Data.data.general.article_name.val = '...';
        this.p_selectedWdm73Data.label = '...';
      }
      for (let i in this.d_partList) {
        for (let pr_ind in this.d_partList[i].param_order) {
          let param = this.d_partList[i].param_order[pr_ind];
          let list_pr = i + '_' + param;
          let list_count = this.f_calculateListCount(list_pr);
          if (list_count > 0) {
            for (let k = 0; k < list_count; k++) {
              let list_pr_info = list_pr + '_info';
              let list_pr_info_id = list_pr + '_' + k.toString() + '_info';
              let txt = document.getElementById(list_pr_info_id).textContent;
              // txt = txt.replace('&nbsp', '');
              // console.log(txt);
              if (txt) {
                let new_list_data = {};
                new_list_data[list_pr_info] = { 'val': txt };
                if (k === 0) {
                  this.p_selectedWdm73Data.data.article_summary[list_pr] = { 'list': [] };
                }
                this.p_selectedWdm73Data.data.article_summary[list_pr].list.push(new_list_data);
              }
            }
          } else {
            this.$delete(this.p_selectedWdm73Data.data.article_summary, list_pr);
          }
        }
      }
      // console.log(this.p_selectedWdm73Data);
      let data = {};
      data = { 'data': this.p_selectedWdm73Data };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt yapılıyor. Lütfen bekleyiniz.' } });
      PoleStarService.save_wdm73(data).then(resp => {
        this.$store.commit('Loading', { 'status': false, 'data': {} });
        if (resp.data.status === "success") {
          // this.d_selectedWdm73Data = resp.data.result.wdm73_data;
          this.p_selectedProjectData = resp.data.result.project_data;
          this.f_motherChildren('article_naming', this.p_selectedWdm73Data.data.general.article_name.val, 'wdm73_' + this.p_selectedWdm73Data.id);
          this.$forceUpdate();
        } else {
          alert('error: ' + resp.data.message);
        }
      });
    },
    f_calculateListCount: function (list_pr) {
      let c = 0;
      let x = true;
      while (x) {
        let list_pr_info = list_pr + '_' + c.toString() + '_info';
        let doc = document.getElementById(list_pr_info);
        if (doc) {
          c += 1;
        } else {
          x = false;
        }
      }
      return c;
    },
    f_motherChildren: function (op, name = '', wdm73_key) {
      if (op === 'article_naming') {
        this.mother_children.a_name = name;
        this.mother_children.wdm73_key = wdm73_key;
      }
      if (this.mother_children[op]) {
        this.mother_children[op] = 0;
      } else {
        this.mother_children[op] = 1;
      }
    }
  },
  watch: {}
};

</script>

<style type="text/css">
.normal-mode {}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

